import React from "react";

import {
  Box,
  Button,
  Callout,
  Dialog,
  FileInput,
  Intent,
  ProgressBar,
  Text
} from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  changeFiles,
  closeImportAnnotationsDialog,
  signedURLsCreate
} from "../slices/importAnnotationsDialog";

const ImportAnnotationsDialog = () => {
  const dispatch = useAppDispatch();
  const importAnnotationsDialog = useAppSelector(state => state.importAnnotationsDialog);

  const closeDialog = () => {
    dispatch(closeImportAnnotationsDialog());
  };
  const cancelUpload = () => {
    importAnnotationsDialog.currentUpload &&
      importAnnotationsDialog.currentUpload.cancelTokenSource.cancel();
  };
  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFiles(e.target.files));
  };
  const uploadFiles = () => {
    dispatch(signedURLsCreate());
  };
  const errorText = importAnnotationsDialog.errorMessage ? (
    <Box>
      <Callout intent={Intent.DANGER}>
        <Text>{importAnnotationsDialog.errorMessage}</Text>
      </Callout>
    </Box>
  ) : null;

  const isUploadInitiated =
    "isPending" in importAnnotationsDialog.fileSignedURLs &&
    importAnnotationsDialog.fileSignedURLs.isPending;
  const otherFileInputArgs = {
    multiple: "multiple"
  };

  return isUploadInitiated && importAnnotationsDialog.currentUpload !== null ? (
    <Dialog isOpen={importAnnotationsDialog.isOpen} appElementId="root">
      <DialogHeader title="Uploading Files..." />
      <DialogBody>
        {Object.values(importAnnotationsDialog.currentUpload.uploadProgress).map((upload, idx) => {
          return (
            <Box key={idx}>
              <h3>{upload.file.name}</h3>
              <ProgressBar mt="2" value={upload.progress} max={upload.file.size} />
            </Box>
          );
        })}
      </DialogBody>
      <DialogFooter>
        <Button onClick={cancelUpload}>Stop</Button>
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog
      isOpen={importAnnotationsDialog.isOpen}
      onRequestClose={closeDialog}
      appElementId="root"
    >
      <DialogHeader title="Select Files" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>
          <FileInput onChange={onChangeFiles} accept=".mld" {...otherFileInputArgs} />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            disabled={importAnnotationsDialog.acceptButtonDisabled}
            onClick={uploadFiles}
            isLoading={isUploadInitiated}
            appearance="prominent"
            intent="primary"
          >
            Upload
          </Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ImportAnnotationsDialog;
