/* eslint-disable functional/immutable-data */
import React, { useEffect, useState } from "react";
import { Appearance, Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import {
  ImageManagementRecord,
  ImageManagementTableColumn,
  ImageStatusType,
  TableColumnVisibilityObject
} from "../models";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableFilters } from "../components/Table";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fetchImageManagementImagesTabCsv,
  fetchImageManagementImagesTabResults,
  openImageFilterDialog,
  setFilters,
  countImageFilters
} from "../slices/imageManagement";
import { useNavigate } from "react-router";
// import ImageManagementBulkImageDialog from "../components/ImageManagementBulkImageDialog";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
// import ImageManagementImageDialogParent from "../components/ImageManagementImageDialogParent";
// import ImageManagementEditTableCellDialog from "../components/ImageManagementEditTableCellDialog";
import {
  ImageRowOptions,
  openImageManagementImageDialogParent
} from "../slices/ImageManagementImageDialogParent";
import { formatDate } from "../utils";
import { RolePermissions } from "../permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChevronDown,
  faPencil,
  faTableColumns
} from "@fortawesome/free-solid-svg-icons";
import {
  CaseTableColumns,
  ImageTableColumns,
  LabImageTableColumns,
  openImageManagementEditTableCellDialog
} from "../slices/ImageManagementEditTableCell";
import UploadSampleDataDialog from "../components/UploadSampleDataDialog";
import { openUploadSampleDataDialog } from "../slices/uploadSampleDataDialog";
// import ImageSearchFilters from "../components/ImageManagementSearchFilters";
import {
  generateTableColumnVisibility,
  SubNavLink,
  TableButton,
  WideTableContainer
} from "../pages/ImageManagement";
import ImageManagementShowHideColumns, {
  IMAGES_TABLE_VISIBILITY_COOKIE_NAME,
  imageTableDefaultColumnVisibility,
  imageTableDefaultRoleColumnVisibility
} from "./ImageManagementShowHideColumns";

export function formatImageStatus(imageStatus: ImageStatusType | null): string | null {
  switch (imageStatus) {
    case ImageStatusType.Processing:
      return "Processing";
    case ImageStatusType.FailProcessing:
      return "Failed Processing";
    case ImageStatusType.DataIncomplete:
      return "Data Incomplete";
    case ImageStatusType.StudyAssignmentPending:
      return "Study Assignment Pending";
    case ImageStatusType.DuplicateImage:
      return "Duplicate Image";
    case ImageStatusType.CaseAssignmentPending:
      return "Case Assignment Pending";
    case ImageStatusType.LabQcPending:
      return "Lab QC Pending";
    case ImageStatusType.IscQcPending:
      return "ISC QC Pending";
    case ImageStatusType.ReadyForReading:
      return "Ready For Reading";
    case ImageStatusType.Complete:
      return "Complete";
    case ImageStatusType.Archived:
      return "Archived";
  }
  return null;
}

const ImageResults = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const imageRecords = useAppSelector(state => state.imageManagement.imageRecords);

  // const isSearchFilterOpen = useAppSelector(state => state.imageManagement.isImageFilterOpen);
  const searchFilters = useAppSelector(state => state.imageManagement.imageResultFilters);

  useEffect(() => {
    dispatch(fetchImageManagementImagesTabResults());
    setVisibleColumns(
      generateTableColumnVisibility(
        visibleColumns,
        imageTableDefaultRoleColumnVisibility,
        loggedInUser,
        IMAGES_TABLE_VISIBILITY_COOKIE_NAME
      )
    );
  }, [searchFilters, loggedInUser]);

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  //const [selectedRows, setSelectedRows] = useState<Array<ImageManagementRecord>>([]);
  const [imageHideShowColumnDialog, setImageHideShowColumnDialog] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState<TableColumnVisibilityObject>(
    generateTableColumnVisibility(
      imageTableDefaultColumnVisibility,
      imageTableDefaultRoleColumnVisibility,
      loggedInUser,
      IMAGES_TABLE_VISIBILITY_COOKIE_NAME
    )
  );

  const error =
    "errorMessage" in imageRecords ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{imageRecords.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const columns: Array<object> = [];

  if (
    "resource" in loggedInUser && // ViewAccessNumber external??
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewAccesionNumber]) &&
    visibleColumns[ImageManagementTableColumn.AccessionNumber]?.visible
  ) {
    columns.push({
      name: "Accession #",
      width: "20rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.accessionNumber || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.accessionNumber || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditAccessionNumber]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.AccessionNumber,
                      row: imageRecord,
                      columnValue: imageRecord.accessionNumber,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.accessionNumber}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewSponsor]) &&
    visibleColumns[ImageManagementTableColumn.Sponsor]?.visible
  ) {
    columns.push({
      name: "Sponsor",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.sponsor || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.sponsor || ""}>{imageRecord.sponsor}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewStudyName]) &&
    visibleColumns[ImageManagementTableColumn.StudyName]?.visible
  ) {
    columns.push({
      name: "Study Name",
      width: "14rem",
      sortable: true,
      wrap: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.studyName || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.studyName || ""}>
          {"resource" in loggedInUser &&
            ((!imageRecord.studyName &&
              loggedInUser.resource.can([RolePermissions.AP_StudyData_EditStudyName])) ||
              (imageRecord.studyName &&
                loggedInUser.resource.can([
                  RolePermissions.AP_StudyData_EditStudyNamePopulated
                ]))) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.StudyName,
                      row: imageRecord,
                      columnValue: imageRecord.studyName,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.studyName}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewProtocolID]) &&
    visibleColumns[ImageManagementTableColumn.ProtocolID]?.visible
  ) {
    columns.push({
      name: "Protocol ID",
      width: "14rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.protocolId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.protocolId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditProtocolID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.ProtocolId,
                      row: imageRecord,
                      columnValue: imageRecord.protocolId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.protocolId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSiteID]) &&
    visibleColumns[ImageManagementTableColumn.SiteID]?.visible
  ) {
    columns.push({
      name: "Site ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.siteId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.siteId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSiteID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.SiteID,
                      row: imageRecord,
                      columnValue: imageRecord.siteId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.siteId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSubjectID]) &&
    visibleColumns[ImageManagementTableColumn.SubjectID]?.visible
  ) {
    columns.push({
      name: "Subject ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.subjectId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.subjectId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSubjectID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.SubjectID,
                      row: imageRecord,
                      columnValue: imageRecord.subjectId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.subjectId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID]) &&
    visibleColumns[ImageManagementTableColumn.VisitID]?.visible
  ) {
    columns.push({
      name: "Visit ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.visitId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.visitId || ""}>
          {!imageRecord.caseId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditVisitID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.VisitID,
                      row: imageRecord,
                      columnValue: imageRecord.visitId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.visitId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID]) &&
    visibleColumns[ImageManagementTableColumn.LabVisitID]?.visible
  ) {
    columns.push({
      name: "Lab Visit ID",
      width: "14rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.labVisitId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.labVisitId || ""}>
          {!imageRecord.caseId &&
            imageRecord.isStudyMatched &&
            (imageRecord.status == ImageStatusType.Processing ||
              imageRecord.status == ImageStatusType.CaseAssignmentPending ||
              imageRecord.status == ImageStatusType.LabQcPending) &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditVisitID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.LabVisitID,
                      row: imageRecord,
                      columnValue: imageRecord.labVisitId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.labVisitId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewHistopathologyProcedureID]) &&
    visibleColumns[ImageManagementTableColumn.HistoProcedureID]?.visible
  ) {
    columns.push({
      name: "Histo Procedure ID",
      width: "25rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.histoProcedureId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.histoProcedureId || ""}>{imageRecord.histoProcedureId}</div>
      ),
      wrap: true
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewEndoProcedureID]) &&
    visibleColumns[ImageManagementTableColumn.EndoProcedureID]?.visible
  ) {
    columns.push({
      name: "Endo Procedure ID",
      width: "25rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.endoProcedureId || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.endoProcedureId || ""}>{imageRecord.endoProcedureId}</div>
      ),
      wrap: true
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewAnatomicalSegment]) &&
    visibleColumns[ImageManagementTableColumn.AnatomicalSegment]?.visible
  ) {
    columns.push({
      name: "Anatomical Segment",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.anatomicalSegment || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.anatomicalSegment || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditAnatomicalSegment]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.AnatomicalSegment,
                      row: imageRecord,
                      columnValue: imageRecord.anatomicalSegment,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.anatomicalSegment}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewCollectionDate]) &&
    visibleColumns[ImageManagementTableColumn.CollectionDate]?.visible
  ) {
    columns.push({
      name: "Collection Date",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.specimenCollectionDate || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.specimenCollectionDate || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditCollectionDate]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.CollectionDate, // Not sure if correct
                      row: imageRecord,
                      columnValue: imageRecord.specimenCollectionDate,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.specimenCollectionDate}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewImageUploadDate]) &&
    visibleColumns[ImageManagementTableColumn.ImageUploadDate]?.visible
  ) {
    columns.push({
      name: "Image Upload Date",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.labImageCreatedAt || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={formatDate(imageRecord.labImageCreatedAt)}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditImageUploadDate]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.ImageUploadDate,
                      row: imageRecord,
                      columnValue: formatDate(imageRecord.labImageCreatedAt),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{formatDate(imageRecord.uploadedAt)}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewImageStatus]) &&
    visibleColumns[ImageManagementTableColumn.ImageStatus]?.visible
  ) {
    columns.push({
      name: "Status",
      grow: 1.6,
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.status || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.status || ""}>{formatImageStatus(imageRecord.status)}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewInQuery]) &&
    visibleColumns[ImageManagementTableColumn.InQuery]?.visible
  ) {
    columns.push({
      name: "In Query",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.inQuery || false
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewFilename]) &&
    visibleColumns[ImageManagementTableColumn.Filename]?.visible
  ) {
    columns.push({
      name: "File Name",
      sortable: true,
      width: "25rem",
      wrap: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.imageFilename || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.imageFilename || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditFileName]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: LabImageTableColumns.Filename,
                      row: imageRecord,
                      columnValue: imageRecord.imageFilename,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.imageFilename}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewQAComments]) &&
    visibleColumns[ImageManagementTableColumn.QAComments]?.visible
  ) {
    columns.push({
      name: "Sample QA Comments",
      wrap: true,
      width: "20rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.qaComments || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div style={{ maxHeight: "5rem", overflow: "hidden" }} title={imageRecord.qaComments || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditQAComments]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: LabImageTableColumns.QaComments,
                      row: imageRecord,
                      columnValue: imageRecord.qaComments,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.qaComments}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageData_ViewUploaderOrganization]) &&
    visibleColumns[ImageManagementTableColumn.Organization]?.visible
  ) {
    columns.push({
      name: "Organization",
      width: "16rem",
      sortable: true,
      selector: (imageRecord: ImageManagementRecord) => imageRecord.organizationName || "",
      format: (imageRecord: ImageManagementRecord) => (
        <div title={imageRecord.organizationName || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageData_EditUploaderOrganization]) && (
              <span
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: ImageTableColumns.Organization,
                      row: imageRecord,
                      columnValue: imageRecord.organizationName,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.organizationName}
        </div>
      )
    });
  }

  // row options
  columns.push({
    right: true,
    grow: 0,
    cell: (imageRecord: ImageManagementRecord) => (
      <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
        <Icon name="menu" />

        <TooltipFlyout placement={"bottom"}>
          {imageRecord.studyId &&
            "resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_AssignImagetoCase]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.AssignToCase,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Assign to Case
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_CopyImage]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.CopyImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Copy Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.S_ImageListTab_MoveImage]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.MoveImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Move Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() => navigate(`/studies/${imageRecord.studyId}/images/${imageRecord.id}`)}
              >
                View Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.DownloadImage,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Download Image
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_Archive]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementImageDialogParent({
                      imageRowOptions: ImageRowOptions.Archive,
                      imageId: imageRecord.id,
                      imageFilename: imageRecord.imageFilename,
                      studyId: imageRecord.studyId
                    })
                  )
                }
              >
                Archive Image
              </TooltipLink>
            )}
        </TooltipFlyout>
      </Button>
    )
  });

  // const onSelectedRowChange = (selected: {
  //   allSelected: boolean;
  //   selectedCount: number;
  //   selectedRows: object[];
  // }) => {
  //   const rows = selected.selectedRows as Array<ImageManagementRecord>;
  //   setSelectedRows(rows);
  // };

  const viewImage = (record: ImageManagementRecord) => {
    navigate(`/studies/${record.studyId}/images/${record.id}`);
  };

  const uploadSampleDataButton = "resource" in loggedInUser &&
    loggedInUser.resource.can([
      RolePermissions.IM_ImagesListTab_UploadImagestoAssignedStudiesOnly
    ]) && (
      <>
        <TableButton iconBefore="upload" onClick={() => dispatch(openUploadSampleDataDialog())}>
          Upload
        </TableButton>
        <UploadSampleDataDialog />
        &nbsp;
      </>
    );

  const exportReportImage = () => {
    dispatch(fetchImageManagementImagesTabCsv());
  };

  const exportReportButtonImage =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_ImagesListTab_ExportImageManagementTable]) ? (
      <TableButton
        iconBefore="download"
        onClick={exportReportImage}
        style={{ marginRight: "0.5rem" }}
      >
        Export Report
      </TableButton>
    ) : null;

  const showHideColumnsButton = (
    <TableButton onClick={() => setImageHideShowColumnDialog(true)}>
      <FontAwesomeIcon icon={faTableColumns} style={{ marginRight: "5px", color: "#9A9DA0" }} />
      Show/Hide Columns&nbsp;{" "}
      {Object.values(visibleColumns).filter(column => !column.visible).length > 0 ? (
        <b style={{ fontWeight: 700 }}>
          ({Object.values(visibleColumns).filter(column => !column.visible).length})
        </b>
      ) : null}
      <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "5px", color: "#3D3E40" }} />
    </TableButton>
  );
  const numImageFilters = countImageFilters(searchFilters);

  const content = error ? (
    error
  ) : (
    <WideTableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={""}
            placeholder={"Search"}
            onValueChange={name => dispatch(setFilters({ ...searchFilters, searchText: name }))}
          />
          <Box paddingLeft="30px" paddingTop="6px">
            <SubNavLink onClick={() => dispatch(openImageFilterDialog())}>
              <Icon name="plus" /> Add Filter {numImageFilters == 0 ? "" : `(${numImageFilters})`}
            </SubNavLink>
          </Box>
          <Box ml="auto">
            <span
              style={{ marginRight: "2rem", cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              <FontAwesomeIcon icon={faArrowsRotate} style={{ color: "#9A9DA0" }} />
            </span>
            {showHideColumnsButton}
            {uploadSampleDataButton}
            {exportReportButtonImage}
          </Box>
        </Box>
      </TableFilters>
      {"resource" in imageRecords ? (
        <StyledDataTable
          columns={columns}
          data={imageRecords.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
          // selectableRows
          // onSelectedRowsChange={onSelectedRowChange}
          fixedHeader={true}
          onRowClicked={viewImage}
        />
      ) : null}
    </WideTableContainer>
  );

  return (
    <>
      {/* <ImageSearchFilters isOpen={isSearchFilterOpen} filters={searchFilters} />
      <ImageManagementImageDialogParent />
      <ImageManagementBulkImageDialog />
      <ImageManagementEditTableCellDialog /> */}
      {"resource" in loggedInUser && (
        <ImageManagementShowHideColumns
          columns={visibleColumns}
          setColumns={setVisibleColumns}
          isDialogOpen={imageHideShowColumnDialog}
          setOpenDialog={setImageHideShowColumnDialog}
          cookieName={IMAGES_TABLE_VISIBILITY_COOKIE_NAME}
          loggedInUser={loggedInUser.resource}
        />
      )}
      {content}
      {/* {selectedRows && selectedRows.length > 0 && "resource" in loggedInUser && (
            <BulkOperationToolbar
              tab="image"
              loggedInUser={loggedInUser.resource}
              selectedCount={selectedRows.length}
            />
          )} */}
    </>
  );
};

export default ImageResults;
