import { Appearance, Button, Icon } from "@blasterjs/core";
import { useEffect, useState } from "react";
import React from "react";
import { UUID } from "../models";

import { Box, Label, TextArea } from "@blasterjs/core";
import { useAppDispatch } from "../hooks";
import { updateCaseComment, updateImageComment } from "../slices/caseImageViewer";
import { LoggedInUser, userIsAdmin } from "../permissions";
import styled from "styled-components";

export enum CommentBoxType {
  Case,
  Image
}

interface CommentBoxState {
  isEditing: boolean;
  comment: string | null;
}

export const QueryBox = styled(Box)`
  background: #f0f2f5;
  padding: 1.6rem;
  border: 1px solid #d2d5df;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

const initialState: CommentBoxState = {
  isEditing: false,
  comment: null
};

interface CommentBoxProps {
  readonly type: CommentBoxType;
  readonly caseId: UUID;
  readonly imageId: UUID | null;
  readonly commentText: string | null;
  readonly loggedInUser: LoggedInUser;
}

const CommentBox = ({ type, caseId, imageId, commentText, loggedInUser }: CommentBoxProps) => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<CommentBoxState>(initialState);
  const userIsAdminLike = userIsAdmin(loggedInUser);
  const userIsReadOnly = loggedInUser.isCR();

  useEffect(() => {
    if (commentText) {
      setState({
        ...state,
        comment: commentText
      });
    }
  }, [commentText]);

  const cancelOnClick = () => {
    setState({
      ...state,
      isEditing: false
    });
  };

  const editOnClick = () => {
    setState({
      ...state,
      isEditing: true
    });
  };

  const addCommentButton = userIsAdminLike ? (
    <Button mt={1} block={true} onClick={editOnClick}>
      Add Comment
    </Button>
  ) : null;

  const commentOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      comment: e.target.value
    });
  };

  const saveOnClick = () => {
    if (state.comment) {
      if (type == CommentBoxType.Case) {
        dispatch(updateCaseComment({ caseId: caseId, comment: state.comment }));
      } else {
        if (imageId) {
          dispatch(
            updateImageComment({ caseId: caseId, imageId: imageId, comment: state.comment })
          );
        }
      }

      setState({
        ...state,
        isEditing: false
      });
    }
  };

  const showComment =
    userIsAdminLike || userIsReadOnly ? (
      <QueryBox>
        <h3>Comments</h3>
        {state.comment}
        <Button
          value={"resource" in loggedInUser ? loggedInUser.user.id : null}
          onClick={editOnClick}
          appearance={Appearance.MINIMAL}
        >
          <Icon name="edit" />
        </Button>
      </QueryBox>
    ) : null;

  const commentForm = userIsAdminLike ? (
    <div>
      <Box>
        <Box>
          <Label>
            Comment:
            <TextArea
              placeholder=""
              mb={2}
              maxLength={255}
              defaultValue={state.comment || ""}
              onChange={commentOnChange}
            />
          </Label>
        </Box>
        <Button
          // isLoading={isSubmitting}
          intent="primary"
          appearance="prominent"
          // disabled={configValidations.errorsExist}
          onClick={saveOnClick}
        >
          Save
        </Button>
        <Button
          style={{
            marginLeft: "15px"
          }}
          // isLoading={isSubmitting}
          onClick={cancelOnClick}
        >
          Cancel
        </Button>
      </Box>
    </div>
  ) : null;

  return <>{state.isEditing ? commentForm : state.comment ? showComment : addCommentButton}</>;
};

export default CommentBox;
