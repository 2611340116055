import React from "react";

import { formateDateToYearMonthDayTime, LOCALE } from "../utils";
import TooltipText from "./TooltipText";

interface Props {
  readonly date: Date | null;
}

const DateYearMonthDayTime = ({ date }: Props) => {
  const formattedDate = formateDateToYearMonthDayTime(date);
  return date === null || date === undefined ? (
    <span>{formattedDate}</span>
  ) : (
    <TooltipText
      text={date.toLocaleTimeString(LOCALE, {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short"
      })}
    >
      {formattedDate}
    </TooltipText>
  );
};

export default DateYearMonthDayTime;
