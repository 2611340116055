import { Button, Callout, Intent, Text, TextArea } from "@blasterjs/core";
import L from "leaflet";
import React, { useEffect } from "react";
import styled from "styled-components";

import {
  cleanup,
  disableDrawHandlers,
  reinitializeAnnotationDrawing,
  removeActiveLayer
} from "./annotationDrawing";
import { DialogHeader } from "./DialogLayout";
import { defaultColor as defaultFreehandAnnotationColor } from "./FreehandAnnotation";
import { defaultProps as defaultPointProps } from "./PointAnnotation";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  AnnotationStatus,
  AnnotationType,
  cancelImageAnnotation,
  createAnnotation,
  createAnnotationEllipse,
  setImageAnnotationText
} from "../slices/caseImageViewer";
import { useMapInstance } from "./ImageViewer";

const InfoSidebarStyled = styled.div`
  width: ${props => props.theme.widths.sidebar.width};
  background-color: #fff;
  padding: 1.5rem 1.3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 30001;
  display: flex;
  flex-direction: column;
`;

interface Props {
  readonly pixelSize: number;
  readonly resolutionInMeters: number;
  readonly scaleFactor: number;
  readonly hpfRadius: number;
  readonly spacebarDown: boolean;
  readonly setSpacebarDown: (x: boolean) => void;
}

const AnnotationSidebar = ({
  pixelSize,
  resolutionInMeters,
  scaleFactor,
  hpfRadius,
  spacebarDown,
  setSpacebarDown
}: Props) => {
  const dispatch = useAppDispatch();

  const mapElement: L.DrawMap | undefined = useMapInstance() as L.DrawMap;

  const annotation = useAppSelector(state => state.caseImageViewer.annotation);
  const selectedAnnotationType =
    annotation.data !== null ? annotation.data.selectedAnnotationType : null;

  const imageWithAnnotations = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);
  const indications =
    imageWithAnnotations &&
    "resource" in imageWithAnnotations &&
    imageWithAnnotations.resource.study
      ? imageWithAnnotations.resource.study.indications
      : null;
  const freehandAnnotationColor =
    (selectedAnnotationType &&
      "freehandAnnotationClass" in selectedAnnotationType &&
      selectedAnnotationType.freehandAnnotationClass &&
      selectedAnnotationType.freehandAnnotationClass.color) ||
    defaultFreehandAnnotationColor;
  const pointAnnotationColor =
    (selectedAnnotationType &&
      "pointAnnotationClass" in selectedAnnotationType &&
      selectedAnnotationType.pointAnnotationClass &&
      selectedAnnotationType.pointAnnotationClass.color) ||
    defaultPointProps.fillColor;

  const selectedMultilineClass = useAppSelector(
    state => state.caseImageViewer.selectedMultilineClass
  );
  const multilineAnnotationColor = selectedMultilineClass?.color || "#0f0";

  const annotationType = selectedAnnotationType ? selectedAnnotationType.type : null;
  const status = annotation.data !== null ? annotation.data.status : null;
  const hideAnnotations = useAppSelector(state => state.caseImageViewer.hideAnnotations);

  useEffect(() => cancel(mapElement), [mapElement]); // Make sure to clean up on unmount
  useEffect(() => {
    if (mapElement) {
      const initEatSpace = () => {
        mapElement.on("keydown", ((e: L.LeafletKeyboardEvent) => {
          if (e.originalEvent.key === " ") {
            // prevent space from rolling to the top div and causing the window to scroll
            setSpacebarDown(true);
            e.originalEvent.preventDefault();
          }
        }) as L.LeafletEventHandlerFn);
        mapElement.on("keyup", ((e: L.LeafletKeyboardEvent) => {
          if (e.originalEvent.key === " ") {
            // prevent space from rolling to the top div and causing the window to scroll
            setSpacebarDown(false);
            e.originalEvent.preventDefault();
          }
        }) as L.LeafletEventHandlerFn);
      };
      if (annotationType) {
        if (status !== AnnotationStatus.Editing) {
          cleanup(mapElement);
        }
        if (status === AnnotationStatus.Placing) {
          reinitializeAnnotationDrawing(
            dispatch,
            mapElement,
            annotationType,
            freehandAnnotationColor,
            pointAnnotationColor,
            multilineAnnotationColor,
            pixelSize,
            resolutionInMeters,
            scaleFactor,
            indications,
            hpfRadius,
            spacebarDown,
            setSpacebarDown
          );
        } else {
          initEatSpace();
        }
      } else {
        // Annotation was cancelled by some other component (eg. deselecting a selected annotation
        // type, selecting a different image in case viewer) so account for that here.
        cleanup(mapElement);
        initEatSpace();
      }
      mapElement.getContainer().focus();
    }
  }, [
    mapElement,
    annotationType,
    status,
    freehandAnnotationColor,
    pointAnnotationColor,
    pixelSize,
    resolutionInMeters,
    scaleFactor,
    indications,
    spacebarDown,
    selectedMultilineClass
  ]);

  useEffect(() => {
    // If annotation hiding is toggled on, cancel any in-progress annotations
    hideAnnotations && cancel(mapElement);
  }, [mapElement, hideAnnotations]);

  const save = () => {
    disableDrawHandlers();
    if (annotationType === AnnotationType.Ellipse) {
      dispatch(createAnnotationEllipse(removeActiveLayer));
    } else {
      dispatch(createAnnotation(removeActiveLayer));
    }
  };
  const cancel = (map: L.DrawMap | undefined) => {
    cleanup(map);
    dispatch(cancelImageAnnotation());
  };
  const annotationTextOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setImageAnnotationText(e.currentTarget.value));
  };
  // NOTE: All annotations must be placed (have `point`) but only text annotations require text
  const saveButtonEnabled =
    annotation.data && annotation.data.form && annotationType && status
      ? ([
          AnnotationType.Freehand,
          AnnotationType.Line,
          AnnotationType.Ellipse,
          AnnotationType.HPF
        ].includes(annotationType) &&
          status === AnnotationStatus.Editing) ||
        (annotationType === AnnotationType.Text &&
          status === AnnotationStatus.Editing &&
          annotation.data &&
          "text" in annotation.data.form &&
          annotation.data.form.text &&
          annotation.data.form.text.length > 0)
      : null;
  const text =
    status === AnnotationStatus.Placing ? (
      annotationType === AnnotationType.Ellipse ? (
        <Text mb={1}>Click on the map, hold down, and drag to draw an ellipse</Text>
      ) : annotationType === AnnotationType.HPF ? (
        <Text mb={1}>Click on the map to place the HPF</Text>
      ) : (
        <Text mb={1}>Click on the map to place your text marker</Text>
      )
    ) : status === AnnotationStatus.Editing ? (
      annotationType === AnnotationType.Ellipse || annotationType === AnnotationType.HPF ? (
        <Text mb={1}>Add message (optional)</Text>
      ) : annotationType === AnnotationType.Text ? (
        <Text mb={1}>Add message</Text>
      ) : null
    ) : null;
  const textArea = annotationType !== AnnotationType.Line &&
    annotationType !== AnnotationType.Freehand && <TextArea onChange={annotationTextOnChange} />;
  const content =
    "errorMessage" in annotation ? (
      <Callout intent={Intent.DANGER}>
        <Text>{annotation.errorMessage}</Text>
      </Callout>
    ) : (
      <>
        <DialogHeader
          title="Add annotation"
          closeDialog={() => cancel(mapElement)}
          style={{ margin: "-1rem -1rem 3rem" }}
        />
        {text}
        {textArea}
        <Button
          disabled={!saveButtonEnabled}
          style={{ width: "100%", marginTop: "auto" }}
          appearance="prominent"
          intent="primary"
          onClick={save}
        >
          Add annotation
        </Button>
      </>
    );
  return annotation.data && annotation.data.form ? (
    <InfoSidebarStyled>{content}</InfoSidebarStyled>
  ) : null;
};

export default AnnotationSidebar;
