import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CaseStatus,
  ImageManagementAnnotationRecords,
  ImageManagementCaseRecord,
  ImageManagementCaseRecords,
  ImageManagementRecord,
  ImageManagementRecords,
  ImageStatusType,
  MldStatus,
  Studies,
  Study
} from "../models";
import {
  fetchImageManagementCasesTab,
  fetchImageManagementCasesTabCsvApi,
  fetchImageManagementImagesTab,
  fetchImageManagementImagesTabCsvApi,
  fetchImageManagementAnnotationsTab,
  fetchStudiesForUser,
  ImageManagementAnnotationSearch,
  ImageManagementCaseSearch,
  ImageManagementDateSearchFilter,
  ImageManagementSearch
} from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";
import { RootState } from "../store";

export interface ImageManagementState {
  readonly imageRecords: Resource<ImageManagementRecords>;
  readonly caseRecords: Resource<ImageManagementCaseRecords>;
  readonly annotationRecords: Resource<ImageManagementAnnotationRecords>;
  // search
  readonly isImageFilterOpen: boolean;
  readonly isCaseFilterOpen: boolean;
  readonly isAnnotationFilterOpen: boolean;
  readonly imageResultFilters: ImageManagementSearch;
  readonly caseResultFilters: ImageManagementCaseSearch;
  readonly annotationResultFilters: ImageManagementAnnotationSearch;
  readonly studies: Resource<Studies>;
}

export function countImageFilters(imif: ImageManagementSearch): number {
  return (
    (imif.searchText.length > 0 ? 1 : 0) +
    (imif.filters.studyFilter ? 1 : 0) +
    (imif.filters.dateFilter ? 1 : 0) +
    (imif.filters.imageStatusFilter ? imif.filters.imageStatusFilter.length : 0)
  );
}

export function countCaseFilters(imcf: ImageManagementCaseSearch): number {
  return (
    (imcf.searchText.length > 0 ? 1 : 0) +
    (imcf.filters.studyFilter ? 1 : 0) +
    (imcf.filters.dateFilter ? 1 : 0) +
    (imcf.filters.caseStatusFilter ? imcf.filters.caseStatusFilter.length : 0)
  );
}

export function countAnnotationFilters(imaf: ImageManagementAnnotationSearch): number {
  return (
    (imaf.searchText.length > 0 ? 1 : 0) +
    (imaf.filters.annotationStatusFilter ? imaf.filters.annotationStatusFilter.length : 0)
  );
}

export const initialState: ImageManagementState = {
  imageRecords: {
    isPending: false
  },
  caseRecords: {
    isPending: false
  },
  annotationRecords: {
    isPending: false
  },

  isImageFilterOpen: false,
  isCaseFilterOpen: false,
  isAnnotationFilterOpen: false,

  imageResultFilters: {
    searchText: "",
    filters: {
      studyFilter: null,
      dateFilter: null,
      imageStatusFilter: null
    }
  },
  caseResultFilters: {
    searchText: "",
    filters: {
      studyFilter: null,
      dateFilter: null,
      caseStatusFilter: null
    }
  },
  annotationResultFilters: {
    searchText: "",
    filters: {
      annotationStatusFilter: null
    }
  },
  studies: {
    isPending: false
  }
};

// thunks
export const fetchImageManagementImagesTabResults = createAsyncThunk(
  "imageManagement/fetchImageManagementImagesTabResults",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchImageManagementImagesTab(state.imageManagement.imageResultFilters);
    return response;
  }
);

export const fetchImageManagementImagesTabCsv = createAsyncThunk(
  "imageManagement/fetchImageManagementImagesTabCsv",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchImageManagementImagesTabCsvApi(
      state.imageManagement.imageResultFilters
    );
    return response;
  }
);

export const fetchImageManagementImagesCaseResults = createAsyncThunk(
  "imageManagement/fetchImageManagementImagesCaseResults",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchImageManagementCasesTab(state.imageManagement.caseResultFilters);
    return response;
  }
);

export const fetchImageManagementCasesTabCsv = createAsyncThunk(
  "imageManagement/fetchImageManagementCasesTabCsv",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchImageManagementCasesTabCsvApi(
      state.imageManagement.caseResultFilters
    );
    return response;
  }
);

export const fetchImageManagementAnnotationTabResults = createAsyncThunk(
  "imageManagement/fetchImageManagementAnnotationTabResults",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchImageManagementAnnotationsTab(
      state.imageManagement.annotationResultFilters
    );
    return response;
  }
);

// search thunks
export const studiesForUserFetch = createAsyncThunk(
  "imageManagement/studiesForUserFetch",
  async (studyName: string) => {
    return await fetchStudiesForUser(studyName);
  }
);

export const imageManagementSlice = createSlice({
  name: "imageManagement",
  initialState: initialState,
  reducers: {
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFilters: (state, action: PayloadAction<ImageManagementSearch>) => {
      state.imageResultFilters = castDraft(action.payload);
    },
    setCaseResultsFilters: (state, action: PayloadAction<ImageManagementCaseSearch>) => {
      state.caseResultFilters = castDraft(action.payload);
    },
    setAnnotationResultsFilters: (
      state,
      action: PayloadAction<ImageManagementAnnotationSearch>
    ) => {
      state.annotationResultFilters = castDraft(action.payload);
    },
    openImageFilterDialog: state => {
      state.isImageFilterOpen = true;
    },
    closeImageFilterDialog: state => {
      state.isImageFilterOpen = false;
    },
    openCaseFilterDialog: state => {
      state.isCaseFilterOpen = true;
    },
    closeCaseFilterDialog: state => {
      state.isCaseFilterOpen = false;
    },
    setDateFilter: (state, action: PayloadAction<ImageManagementDateSearchFilter>) => {
      state.imageResultFilters.filters.dateFilter = action.payload;
    },
    setCaseDateFilter: (state, action: PayloadAction<ImageManagementDateSearchFilter>) => {
      state.caseResultFilters.filters.dateFilter = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<Array<ImageStatusType> | null>) => {
      state.imageResultFilters.filters.imageStatusFilter = action.payload;
    },
    setCaseStatusFilter: (state, action: PayloadAction<Array<CaseStatus> | null>) => {
      state.caseResultFilters.filters.caseStatusFilter = action.payload;
    },
    setAnnotationStatusFilter: (state, action: PayloadAction<MldStatus | null>) => {
      state.annotationResultFilters.filters.annotationStatusFilter = action.payload;
    },
    setStudyName: (state, action) => {
      state.imageResultFilters.filters.studyFilter = {
        searchText: action.payload,
        selectedIds: state.imageResultFilters.filters.studyFilter?.selectedIds || [],
        selected: state.imageResultFilters.filters.studyFilter?.selected || []
      };
    },
    setCaseStudyName: (state, action) => {
      state.caseResultFilters.filters.studyFilter = {
        searchText: action.payload,
        selectedIds: state.caseResultFilters.filters.studyFilter?.selectedIds || [],
        selected: state.caseResultFilters.filters.studyFilter?.selected || []
      };
    },
    clearSearchFilter: state => {
      state.imageResultFilters = {
        searchText: "",
        filters: {
          studyFilter: null,
          dateFilter: null,
          imageStatusFilter: null
        }
      };
      state.caseResultFilters = {
        searchText: "",
        filters: {
          studyFilter: null,
          dateFilter: null,
          caseStatusFilter: null
        }
      };
      state.annotationResultFilters = {
        searchText: "",
        filters: {
          annotationStatusFilter: null
        }
      };
    },
    addStudySelection: (state, action: PayloadAction<Study>) => {
      state.imageResultFilters.filters.studyFilter = {
        searchText: "",
        selectedIds: [
          ...(state.imageResultFilters.filters.studyFilter
            ? state.imageResultFilters.filters.studyFilter.selectedIds
            : []),
          castDraft(action.payload.id)
        ],
        selected: [
          ...(state.imageResultFilters.filters.studyFilter
            ? state.imageResultFilters.filters.studyFilter.selected
            : []),
          castDraft(action.payload)
        ]
      };
    },
    addCaseStudySelection: (state, action: PayloadAction<Study>) => {
      state.caseResultFilters.filters.studyFilter = {
        searchText: "",
        selectedIds: [
          ...(state.caseResultFilters.filters.studyFilter
            ? state.caseResultFilters.filters.studyFilter.selectedIds
            : []),
          castDraft(action.payload.id)
        ],
        selected: [
          ...(state.caseResultFilters.filters.studyFilter
            ? state.caseResultFilters.filters.studyFilter.selected
            : []),
          castDraft(action.payload)
        ]
      };
    },
    removeStudySelection: (state, action: PayloadAction<Study>) => {
      if (state.imageResultFilters.filters.studyFilter) {
        state.imageResultFilters.filters.studyFilter.selectedIds = state.imageResultFilters.filters.studyFilter.selected
          .filter(study => study.id !== action.payload.id)
          .map(s => s.id);
        state.imageResultFilters.filters.studyFilter.selected = state.imageResultFilters.filters.studyFilter.selected.filter(
          study => study.id !== action.payload.id
        );
      }
    },
    removeCaseStudySelection: (state, action: PayloadAction<Study>) => {
      if (state.caseResultFilters.filters.studyFilter) {
        state.caseResultFilters.filters.studyFilter.selectedIds = state.caseResultFilters.filters.studyFilter.selected
          .filter(study => study.id !== action.payload.id)
          .map(s => s.id);
        state.caseResultFilters.filters.studyFilter.selected = state.caseResultFilters.filters.studyFilter.selected.filter(
          study => study.id !== action.payload.id
        );
      }
    },
    updateImageRecordRow: (state, action: PayloadAction<ImageManagementRecord>) => {
      if (action.payload.id && "resource" in state.imageRecords) {
        state.imageRecords.resource[
          state.imageRecords.resource.findIndex(ir => ir.id == action.payload.id)
        ] = action.payload;
      }
    },
    updateCaseRecordRow: (state, action: PayloadAction<ImageManagementCaseRecord>) => {
      if (action.payload.caseId && "resource" in state.caseRecords) {
        state.caseRecords.resource[
          state.caseRecords.resource.findIndex(ir => ir.caseId == action.payload.caseId)
        ] = castDraft(action.payload);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(studiesForUserFetch.pending, state => {
      state.studies = { isPending: true };
    });
    builder.addCase(studiesForUserFetch.fulfilled, (state, action) => {
      state.studies = { resource: castDraft(action.payload) };
    });
    builder.addCase(studiesForUserFetch.rejected, (state, action) => {
      state.studies = { errorMessage: action.error.message || "" };
    });

    builder.addCase(fetchImageManagementImagesTabResults.pending, state => {
      state.imageRecords = { isPending: true };
    });
    builder.addCase(fetchImageManagementImagesTabResults.fulfilled, (state, action) => {
      state.imageRecords = { resource: castDraft(action.payload) };
    });
    builder.addCase(fetchImageManagementImagesTabResults.rejected, (state, action) => {
      state.imageRecords = { errorMessage: action.error.message || "" };
    });

    builder.addCase(fetchImageManagementImagesCaseResults.pending, state => {
      state.caseRecords = { isPending: true };
    });
    builder.addCase(fetchImageManagementImagesCaseResults.fulfilled, (state, action) => {
      state.caseRecords = { resource: castDraft(action.payload) };
    });
    builder.addCase(fetchImageManagementImagesCaseResults.rejected, (state, action) => {
      state.caseRecords = { errorMessage: action.error.message || "" };
    });

    builder.addCase(fetchImageManagementAnnotationTabResults.pending, state => {
      state.annotationRecords = { isPending: true };
    });
    builder.addCase(fetchImageManagementAnnotationTabResults.fulfilled, (state, action) => {
      state.annotationRecords = { resource: castDraft(action.payload) };
    });
    builder.addCase(fetchImageManagementAnnotationTabResults.rejected, (state, action) => {
      state.annotationRecords = { errorMessage: action.error.message || "" };
    });
  }
});

export const {
  setFilters,
  setDateFilter,
  setCaseDateFilter,
  setStatusFilter,
  setCaseStatusFilter,
  setAnnotationStatusFilter,
  setStudyName,
  setCaseStudyName,
  clearSearchFilter,
  addStudySelection,
  addCaseStudySelection,
  removeStudySelection,
  removeCaseStudySelection,
  setCaseResultsFilters,
  setAnnotationResultsFilters,
  openImageFilterDialog,
  closeImageFilterDialog,
  openCaseFilterDialog,
  closeCaseFilterDialog,
  updateImageRecordRow,
  updateCaseRecordRow
} = imageManagementSlice.actions;
export default imageManagementSlice.reducer;
