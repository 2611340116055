import { Appearance, Button, Icon, Text } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";

const ExpandableContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 11000;
  background: #fff;
`;

interface ExpandedProps {
  readonly isExpanded: boolean;
}

const CollapsedIconContainer = styled.div`
  background: #fff;
  cursor: pointer;
  padding-left: 16px;
`;

interface ToggleProps {
  readonly onToggle: (e: React.MouseEvent) => void;
}

type ExpandCollapseIconProps = ExpandedProps & ToggleProps;

const ExpandCollapseIcon = ({ isExpanded, onToggle }: ExpandCollapseIconProps) => (
  <Button
    appearance={Appearance.MINIMAL}
    onClick={onToggle}
    title={isExpanded ? "Collapse" : "Expand"}
  >
    <Icon name={isExpanded ? "collapse" : "expand"} />
  </Button>
);

export const CollapseIcon = ({ onToggle }: Pick<ExpandCollapseIconProps, "onToggle">) => (
  <ExpandCollapseIcon isExpanded={true} onToggle={onToggle} />
);

type Props = {
  readonly label: string;
  readonly children?: React.ReactNode;
} & ExpandedProps &
  ToggleProps;

const Expandable = ({ label, children, isExpanded, onToggle }: Props) => (
  <ExpandableContainer>
    {isExpanded ? (
      <>
        {/*
          When expanded, component consumer is responsible for using icon to
          collapse. This gives the consumer utmost flexibility in terms of
          where the collapse icon is placed.
        */}
        {children}
      </>
    ) : (
      <CollapsedIconContainer onClick={onToggle}>
        <Text mr={1} fontSize={1} style={{ position: "relative", top: "-2px" }}>
          {label}
        </Text>
        <ExpandCollapseIcon
          isExpanded={false}
          onToggle={() => {
            // no-op (toggling handled by container)
          }}
        />
      </CollapsedIconContainer>
    )}
  </ExpandableContainer>
);

export default Expandable;
