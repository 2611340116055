import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Annotation,
  Case,
  CaseStatus,
  CaseWithImages,
  FixedQueryCategory,
  Image,
  ImageWithAnnotations,
  MultilineAnnotation,
  OtherQueryCategory,
  QueryObjectType,
  MultilineCategory,
  MultilineCell,
  Fragment,
  PointAnnotation
} from "../models";
import {
  addComment,
  closeQueryApi,
  createAnnotationApi,
  createQuery,
  deleteAnnotationApi,
  fetchCase,
  fetchImage,
  forwardCaseStatus,
  markQueryUnresolvable,
  newQueryFollowUpReminder,
  passQcForImage,
  patchAcceptAiAnnotations,
  toggleImageVisibility
} from "../api";
import { Resource, WriteResource } from "../types";
import { LatLngLiteral } from "leaflet";

import {
  FreehandAnnotationClass,
  MultilineAnnotationClass,
  PointAnnotationClass,
  HpfAnnotationClass,
  UUID
} from "../models";
import { RootState } from "../store";
import {
  pointToGeoJSON,
  polygonToGeoJSON,
  lineToGeoJSON,
  getNextUnusedMultilineCell,
  mapStateToFragments
} from "../utils";
import { castDraft } from "immer";
import { redirectAction } from "./auth";

export type QueryEditorForm =
  | {
      readonly category: FixedQueryCategory | null;
    }
  | {
      readonly category: OtherQueryCategory;
      readonly text: string;
    };

export interface CaseImageViewerState {
  readonly histoCase: Resource<CaseWithImages> | null;
  readonly imageWithAnnotations: Resource<ImageWithAnnotations> | null;
  readonly hideAnnotations: boolean;
  readonly allHighlightedAnnotations: ReadonlyArray<Annotation>;
  readonly highlightedAnnotations: ReadonlyArray<string>;
  readonly brightnessAdjust: number;
  readonly annotationOpacity: number | null;
  readonly queryEditor: QueryEditorForm | null;
  readonly annotation: WriteResource<AnnotationForm<SelectedAnnotationType> | null, Annotation>;
  readonly tempHpf: LatLngLiteral | null;
  readonly hpfCursorColor: string | null;
  readonly isSidebarExpanded: boolean;
  readonly isMicroscopeActive: boolean;
  readonly selectedMultilineClass: MultilineAnnotationClass | null;
  readonly selectedMultilineCategory: MultilineCategory | null;
  readonly hpfVisibility: ReadonlyArray<HpfVisibility>;
  readonly celiacTableVisibility: boolean;
}

export const initialState: CaseImageViewerState = {
  histoCase: {
    isPending: false
  },
  imageWithAnnotations: {
    isPending: false
  },
  hideAnnotations: false,
  allHighlightedAnnotations: [],
  highlightedAnnotations: [],
  brightnessAdjust: 0,
  annotationOpacity: 100,
  queryEditor: null,
  annotation: {
    data: null
  },
  tempHpf: null,
  hpfCursorColor: null,
  isSidebarExpanded: true,
  isMicroscopeActive: false,
  selectedMultilineClass: null,
  selectedMultilineCategory: null,
  hpfVisibility: [],
  celiacTableVisibility: true
};

/* annotations  */

// This form is for annotation types that can be _edited_ (moved around or text added). Several
// types of annotations cannot be edited.
export interface BaseAnnotationForm {
  readonly geometry: LatLngLiteral;
}
export type HPFAnnotationForm = BaseAnnotationForm & {
  readonly radiusX: number;
  readonly text: string | null;
  readonly tempHpf: LatLngLiteral | null;
};
export type EllipseAnnotationForm = BaseAnnotationForm & {
  readonly radiusX: number;
  readonly radiusY: number;
  readonly tilt: number;
  readonly text: string | null;
};
export type TextAnnotationForm = BaseAnnotationForm & {
  readonly text: string;
};

export enum AnnotationStatus {
  Dropping = "DROPPING",
  Placing = "PLACING",
  Editing = "EDITING"
}

export enum AnnotationType {
  Line = "LINE",
  Multiline = "MULTILINE",
  Ellipse = "ELLIPSE",
  HPF = "HPF",
  Text = "TEXT",
  Point = "POINT",
  Freehand = "FREEHAND"
}

export interface LineAnnotationType {
  readonly type: AnnotationType.Line;
}

export interface MultilineAnnotationType {
  readonly type: AnnotationType.Multiline;
}

export interface EllipseAnnotationType {
  readonly type: AnnotationType.Ellipse;
}

export interface TextAnnotationType {
  readonly type: AnnotationType.Text;
}

// NOTE: These annotation types are special in that they have extra info associated with them
// (annotation class, which determines their color).
export interface HpfAnnotationType {
  readonly type: AnnotationType.HPF;
  readonly hpfAnnotationClass: HpfAnnotationClass | null;
}

export interface PointAnnotationType {
  readonly type: AnnotationType.Point;
  readonly pointAnnotationClass: PointAnnotationClass | null;
}

export interface FreehandAnnotationType {
  readonly type: AnnotationType.Freehand;
  readonly freehandAnnotationClass: FreehandAnnotationClass | null;
}

export interface MultilineAnnotationType {
  readonly type: AnnotationType.Multiline;
  readonly multilineAnnotationClass: MultilineAnnotationClass | null;
  readonly multilineAnnotationCategory: MultilineCategory | null;
}

export type SelectedAnnotationType = EditableAnnotationType | UneditableAnnotationType;

export type EditableAnnotationType = EllipseAnnotationType | HpfAnnotationType | TextAnnotationType;

// NOTE: These annotation types cannot be edited; they are placed/drawn and saved directly
export type UneditableAnnotationType =
  | PointAnnotationType
  //| HpfAnnotationType
  | LineAnnotationType
  | MultilineAnnotationType
  | FreehandAnnotationType;

export type FormFromEditableAnnotationType<
  T extends EditableAnnotationType
> = T extends EllipseAnnotationType
  ? EllipseAnnotationForm
  : T extends TextAnnotationType
  ? TextAnnotationForm
  : T extends HpfAnnotationType
  ? HPFAnnotationForm
  : never;

export type AnnotationForm<T extends SelectedAnnotationType> =
  | {
      readonly form: null;
      readonly status: AnnotationStatus.Placing;
      readonly selectedAnnotationType: T;
    }
  | {
      readonly form: null;
      readonly status: AnnotationStatus.Dropping;
      readonly tempHpf: LatLngLiteral | null;
      readonly selectedAnnotationType: T;
    }
  | {
      readonly form: FormFromEditableAnnotationType<Exclude<T, UneditableAnnotationType>>;
      readonly status: AnnotationStatus.Editing;
      readonly selectedAnnotationType: Exclude<T, UneditableAnnotationType>;
    };

export type HpfVisibility = {
  readonly hpfClassid: string | null;
  readonly hpfId: string | null;
  readonly pointClassId: string | null;
};

export type HpfVisibilityPayload = {
  readonly hpfVisibility: HpfVisibility;
  readonly fragment: Fragment;
};

function highlightAllImage(
  iwa: ImageWithAnnotations,
  ids: readonly string[]
): readonly Annotation[] {
  return [...iwa.manualAnnotations, ...iwa.aiAnnotations].map((anno: Annotation) => {
    return anno !== null
      ? ids.includes(anno.id)
        ? { ...anno, weight: 10 }
        : { ...anno, weight: 5 }
      : anno;
  });
}

function highlightAll(
  iwa: Resource<ImageWithAnnotations> | null,
  ids: readonly string[]
): readonly Annotation[] {
  return iwa && "resource" in iwa ? highlightAllImage(iwa.resource, ids) : [];
}

function addId(annos: readonly string[], id: string): readonly string[] {
  if (annos.find(e => e === id)) {
    return annos;
  } else {
    return [...annos, id];
  }
}

function removeId(annos: readonly string[], id: string): readonly string[] {
  return annos.filter(anno => anno !== id);
}

// thunks
export const caseFetch = createAsyncThunk(
  "caseImageViewer/caseFetch",
  async (caseId: string, thunkApi) => {
    const { dispatch } = thunkApi;
    const fetchCaseResponse = await fetchCase(caseId);

    const imageToFetchId =
      "images" in fetchCaseResponse && fetchCaseResponse.images.length > 0
        ? fetchCaseResponse.images[0]?.id
        : "imageQueries" in fetchCaseResponse &&
          fetchCaseResponse.imageQueries[0] &&
          fetchCaseResponse.imageQueries[0][0]
        ? fetchCaseResponse.imageQueries[0][0].image.id
        : null;

    if (imageToFetchId) {
      await dispatch(imageFetch(imageToFetchId));
    }
    return fetchCaseResponse;
  }
);

export const refreshCase = createAsyncThunk(
  "caseImageViewer/refreshCase",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = getState() as RootState;
    if (state.caseImageViewer.histoCase !== null && "resource" in state.caseImageViewer.histoCase) {
      const fetchCaseResponse = await fetchCase(
        state.caseImageViewer.histoCase.resource.caseWithStatus.id
      );
      const updatedState = getState() as RootState;

      const selectedImageId =
        updatedState.caseImageViewer.imageWithAnnotations &&
        "resource" in updatedState.caseImageViewer.imageWithAnnotations
          ? updatedState.caseImageViewer.imageWithAnnotations.resource.imageAndQuery.image.id
          : null;

      const isSelectedImageInCase =
        selectedImageId !== null &&
        fetchCaseResponse.images.map(image => image.id).includes(selectedImageId);

      if (selectedImageId && isSelectedImageInCase) {
        await dispatch(refreshImage({ imageId: selectedImageId }));
      } else {
        if (fetchCaseResponse.images[0]) {
          await dispatch(selectImage(fetchCaseResponse.images[0].id));
        }
      }

      return fetchCaseResponse;
    }
  }
);

export interface RefreshImageParams {
  readonly imageId: UUID;
  readonly refreshCaseOnSuccess?: boolean;
}
export const refreshImage = createAsyncThunk(
  "caseImageViewer/refreshImage",
  async (params: RefreshImageParams, thunkApi) => {
    const { dispatch } = thunkApi;

    const response = await fetchImage(params.imageId);
    if (params.refreshCaseOnSuccess) {
      await dispatch(refreshCase());
    }
    return response;
  }
);

export const transitionCaseStatus = createAsyncThunk(
  "caseImageViewer/transitionCaseStatus",
  async (c: Case, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;
    const embedded = rootState.config.embedded;
    if (state.histoCase !== null && "resource" in state.histoCase) {
      const forwardCaseStatusResponse = await forwardCaseStatus(
        state.histoCase.resource.caseWithStatus.id
      );

      if (forwardCaseStatusResponse.caseWithStatus.status === CaseStatus.Completed) {
        if (embedded) {
          dispatch(redirectAction("/successMessage"));
        } else {
          dispatch(redirectAction(`/studies/${forwardCaseStatusResponse.caseWithStatus.studyId}`));
        }
      }

      return forwardCaseStatusResponse;
    }
  }
);

export const toggleAcceptAiAnnotations = createAsyncThunk(
  "caseImageViewer/toggleAcceptAiAnnotations",
  async (imageId: UUID, _) => await patchAcceptAiAnnotations(imageId)
);

export const transitionImageStatus = createAsyncThunk(
  "caseImageViewer/transitionImageStatus",
  async (image: Image, thunkApi) => {
    const { dispatch } = thunkApi;
    const resp = await passQcForImage(image.id);
    await dispatch(refreshImage({ imageId: image.id }));
    return resp;
  }
);

export interface UpdateCaseComment {
  readonly caseId: string;
  readonly comment: string;
}

export interface UpdateImageComment {
  readonly caseId: string;
  readonly imageId: string;
  readonly comment: string;
}

export const updateCaseComment = createAsyncThunk(
  "caseImageViewer/updateCaseComment",
  async (params: UpdateCaseComment) => {
    const response = await addComment(params.caseId, null, params.comment);
    return response;
  }
);

export const updateImageComment = createAsyncThunk(
  "caseImageViewer/updateImageComment",
  async (params: UpdateImageComment) => {
    const response = await addComment(params.caseId, params.imageId, params.comment);
    return response;
  }
);

export interface OpenQueryParams {
  readonly queryObjectType: QueryObjectType;
  readonly objectId: UUID;
  readonly studyId: UUID;
  readonly caseId: UUID;
  readonly category: string;
  readonly categoryOtherText: string | null;
  readonly followUpInDays: number;
  readonly commentText: string | null;
  readonly withRoleId: UUID | null;
  readonly responseOptionId: UUID | null;
  readonly childResponseOptionId: string | null;
}

export const openQuery = createAsyncThunk(
  "caseImageViewer/openQuery",
  async (params: OpenQueryParams, thunkApi) => {
    const { dispatch } = thunkApi;

    const response = await createQuery(
      params.queryObjectType,
      params.objectId,
      params.studyId,
      params.caseId,
      params.category,
      params.categoryOtherText,
      params.followUpInDays,
      params.commentText,
      params.withRoleId,
      params.responseOptionId,
      params.childResponseOptionId
    );
    await dispatch(refreshCase());

    return response;
  }
);

export interface CloseQueryParams {
  readonly queryId: string;
  readonly detailedReasonText: string;
}

export const closeQuery = createAsyncThunk(
  "caseImageViewer/closeQuery",
  async (params: CloseQueryParams, thunkApi) => {
    const { dispatch } = thunkApi;

    const response = await closeQueryApi(params.queryId, params.detailedReasonText);
    await dispatch(refreshCase());
    return response;
  }
);

export interface UnresolvableQueryParams {
  readonly queryId: string;
  readonly unresolvableText: string;
  readonly refreshImageOnSuccess: UUID | null;
}

export const unresolvableQuery = createAsyncThunk(
  "caseImageViewer/unresolvableQuery",
  async (params: UnresolvableQueryParams, thunkApi) => {
    const { dispatch } = thunkApi;

    const response = await markQueryUnresolvable(params.queryId, params.unresolvableText);

    if (params.refreshImageOnSuccess) {
      await dispatch(refreshImage({ imageId: params.refreshImageOnSuccess }));
    } else {
      await dispatch(refreshCase());
    }

    return response;
  }
);

export interface NewQueryReminderParams {
  readonly queryId: string;
  readonly queryReminderId: string;
  readonly followUpInDays: number;
}

// annotation params
export interface BeginAnnotationParams {
  selectedAnnotationType: SelectedAnnotationType;
  imageId: UUID;
}

export interface BeginDroppingAnnotationParams {
  selectedAnnotationType: SelectedAnnotationType;
  imageId: UUID;
}
export interface SetImageAnnotationForEllipseTypeParams {
  point: LatLngLiteral;
  radiusX: number;
  radiusY: number;
  tilt: number;
}
export interface SetImageAnnotationForHPFTypeParams {
  point: LatLngLiteral;
  radius: number;
}

export interface CreateFreehandImageAnnotationParams {
  positions: ReadonlyArray<LatLngLiteral>;
  cleanupCallback: () => void;
}

export interface CreateLineImageAnnotationParams {
  points: ReadonlyArray<LatLngLiteral>;
  cleanupCallback: () => void;
}

export interface SelectNextUnusedMultilineClassParams {
  continue: boolean;
}

export interface CreateMultilineImageAnnotationParams {
  points: ReadonlyArray<LatLngLiteral>;
  cleanupCallback: () => void;
}

export interface CreatePointImageAnnotationParams {
  point: LatLngLiteral;
  hpfRadius: number;
  cleanupCallback: () => void;
}

// export interface CancelImageAnnotationParams {
//   point: LatLngLiteral;
//   radius: number;
//   cleanupCallback: () => void;
// }

// export interface CallbackParam {
//   cleanupCallback: () => void;
// }

// thunks
export const newQueryReminder = createAsyncThunk(
  "caseImageViewer/newQueryReminder",
  async (params: NewQueryReminderParams, thunkApi) => {
    const { dispatch } = thunkApi;

    const response = await newQueryFollowUpReminder(
      params.queryId,
      params.queryReminderId,
      params.followUpInDays
    );
    await dispatch(refreshCase());

    return response;
  }
);

export const selectImage = createAsyncThunk(
  "caseImageViewer/selectImage",
  async (imageId: UUID, thunkApi) => {
    const { dispatch } = thunkApi;
    await dispatch(refreshImage({ imageId: imageId }));
  }
);

export const imageFetch = createAsyncThunk("caseImageViewer/imageFetch", async (imageId: UUID) => {
  const response = await fetchImage(imageId);
  return response;
});

export const toggleImageForCR = createAsyncThunk(
  "caseImageViewer/hideImageForReader",
  async (imageId: UUID) => {
    const response = await toggleImageVisibility(imageId);
    //TODO this would be better await dispatch(refreshCase());
    window.location.reload();
    return response;
  }
);

// annotation thunks
//
export const beginDroppingAnnotation = createAsyncThunk(
  "caseImageViewer/beginDroppingAnnotation",
  async (params: BeginDroppingAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      dispatch(setBeginDroppingAnnotation(params));
      await dispatch(
        refreshImage({ imageId: state.imageWithAnnotations.resource.imageAndQuery.image.id })
      );
    }
  }
);

export const createFreehandImageAnnotation = createAsyncThunk(
  "caseImageViewer/createFreehandImageAnnotation",
  async (params: CreateFreehandImageAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    const keepAnnotating = true;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      const createAnnotationResponse = await createAnnotationApi(
        polygonToGeoJSON(params.positions),
        null,
        null,
        null,
        null,
        state.annotation.data &&
          "freehandAnnotationClass" in state.annotation.data.selectedAnnotationType &&
          state.annotation.data.selectedAnnotationType.freehandAnnotationClass
          ? state.annotation.data.selectedAnnotationType.freehandAnnotationClass.id
          : null,
        "FREEHAND",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      );

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: params.cleanupCallback
        })
      );

      return createAnnotationResponse;
    }
  }
);

export const createLineImageAnnotation = createAsyncThunk(
  "caseImageViewer/createLineImageAnnotation",
  async (params: CreateLineImageAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    const keepAnnotating = true;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      await createAnnotationApi(
        lineToGeoJSON(params.points),
        null,
        null,
        null,
        null,
        null,
        "LINE",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      );

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: params.cleanupCallback
        })
      );
    }
  }
);

export const selectNextUnusedMultilineClass = createAsyncThunk(
  "caseImageViewer/selectNextUnusedMultilineClass",
  (params: SelectNextUnusedMultilineClassParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      const annotations =
        state.imageWithAnnotations && "resource" in state.imageWithAnnotations
          ? state.imageWithAnnotations.resource.manualAnnotations
          : [];
      const multilineAnnotations = annotations.filter(
        annotation => annotation.annotationType == AnnotationType.Multiline
      ) as MultilineAnnotation[];

      const selectedMultilineAnnotationClass = state.selectedMultilineClass;
      const selectedMultilineAnnotationCategory = state.selectedMultilineCategory;

      if (
        selectedMultilineAnnotationClass &&
        selectedMultilineAnnotationClass !== null &&
        "id" in selectedMultilineAnnotationClass &&
        selectedMultilineAnnotationCategory !== null
      ) {
        const annotation = multilineAnnotations.find(
          annotation =>
            annotation.annotationClassId === selectedMultilineAnnotationClass.id &&
            annotation.multilineCategory === selectedMultilineAnnotationCategory
        );
        if (annotation === undefined) {
          return;
        }
      }

      const start =
        params.continue &&
        selectedMultilineAnnotationClass !== null &&
        selectedMultilineAnnotationCategory !== null
          ? <MultilineCell>{
              class: selectedMultilineAnnotationClass,
              category: selectedMultilineAnnotationCategory
            }
          : undefined;

      const multilineCell = getNextUnusedMultilineCell(
        state.imageWithAnnotations.resource,
        undefined,
        start
      );

      if (multilineCell) {
        dispatch(setSelectedMultilineClass(multilineCell.class));
        dispatch(setSelectedMultilineCategory(multilineCell.category));
      }
    }
  }
);

export const createMultilineImageAnnotation = createAsyncThunk(
  "caseImageViewer/createMultilineImageAnnotation",
  async (params: CreateMultilineImageAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      const exclude = <MultilineCell>{
        class: state.selectedMultilineClass,
        category: state.selectedMultilineCategory
      };

      const nextUnusedMultilineCell = getNextUnusedMultilineCell(
        state.imageWithAnnotations.resource,
        exclude,
        <MultilineCell>{
          class: state.selectedMultilineClass,
          category: state.selectedMultilineCategory
        }
      );

      const keepAnnotating = nextUnusedMultilineCell ? true : false;

      await createAnnotationApi(
        lineToGeoJSON(params.points),
        null,
        null,
        null,
        null,
        state.selectedMultilineClass ? state.selectedMultilineClass.id : null,
        "MULTILINE",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        state.selectedMultilineCategory
      ).then(() => {
        if (!state.celiacTableVisibility) {
          dispatch(toggleCeliacTableVisibility());
        }
      });

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: params.cleanupCallback
        })
      );

      await dispatch(selectNextUnusedMultilineClass({ continue: true }));
    }
  }
);

export const createPointImageAnnotation = createAsyncThunk(
  "caseImageViewer/createPointImageAnnotation",
  async (params: CreatePointImageAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    const keepAnnotating = true;

    const pointClass =
      state.annotation.data &&
      "pointAnnotationClass" in state.annotation.data.selectedAnnotationType &&
      state.annotation.data.selectedAnnotationType.pointAnnotationClass
        ? state.annotation.data.selectedAnnotationType.pointAnnotationClass.id
        : null;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      await createAnnotationApi(
        pointToGeoJSON(params.point),
        params.hpfRadius,
        null,
        null,
        null,
        pointClass,
        "POINT",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      ).then(response => {
        const pt = response as PointAnnotation;
        const hpfId = pt.parent || "no-id2";

        const topLevelAnnotations = mapStateToFragments(rootState);
        const anno = topLevelAnnotations.find(x => x.hpfs?.findIndex(y => y.id === hpfId) !== -1);

        if (
          anno &&
          state.hpfVisibility.find(
            x =>
              x.pointClassId === pointClass ||
              (x.hpfId === hpfId && x.pointClassId === null) ||
              (x.hpfClassid === anno.id && x.hpfId === null && x.pointClassId === null)
          )
        ) {
          dispatch(
            toggleHpfVisibility({
              fragment: anno,
              hpfVisibility: {
                hpfClassid: anno.id || null,
                hpfId: hpfId,
                pointClassId: pointClass
              }
            })
          );
        }
      });

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: params.cleanupCallback
        })
      );
    }
  }
);

export const createAnnotationEllipse = createAsyncThunk(
  "caseImageViewer/createAnnotationEllipse",
  async (cleanupCallback: () => void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    const keepAnnotating = false;

    if (
      state.imageWithAnnotations &&
      "resource" in state.imageWithAnnotations &&
      state.annotation.data &&
      state.annotation.data.form
    ) {
      await createAnnotationApi(
        pointToGeoJSON(state.annotation.data.form.geometry),
        "radiusX" in state.annotation.data.form ? state.annotation.data.form.radiusX : null,
        "radiusY" in state.annotation.data.form ? state.annotation.data.form.radiusY : null,
        "tilt" in state.annotation.data.form ? state.annotation.data.form.tilt : null,
        "text" in state.annotation.data.form ? state.annotation.data.form.text : null,
        null,
        "ELLIPSE",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      );

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: cleanupCallback
        })
      );
    }
  }
);

export interface CreateHpfImageAnnotationParams {
  point: LatLngLiteral;
  radius: number;
  cleanupCallback: () => void;
}
export const createHpfImageAnnotation = createAsyncThunk(
  "caseImageViewer/createHpfImageAnnotation",
  async (params: CreateHpfImageAnnotationParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    const keepAnnotating = true;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      const response = await createAnnotationApi(
        pointToGeoJSON(params.point),
        params.radius,
        null,
        null,
        null,
        state.annotation.data &&
          "hpfAnnotationClass" in state.annotation.data.selectedAnnotationType &&
          state.annotation.data.selectedAnnotationType.hpfAnnotationClass
          ? state.annotation.data.selectedAnnotationType.hpfAnnotationClass.id
          : null,
        "HPF",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      );

      await dispatch(
        createImageAnnotationSuccess({
          keepAnnotating: keepAnnotating,
          cleanupCallback: params.cleanupCallback
        })
      );

      return response;
    }
  }
);

export const createAnnotation = createAsyncThunk(
  "caseImageViewer/createAnnotation",
  async (cleanupCallback: () => void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    if (
      state.imageWithAnnotations &&
      "resource" in state.imageWithAnnotations &&
      state.annotation.data &&
      state.annotation.data.form
    ) {
      await createAnnotationApi(
        pointToGeoJSON(state.annotation.data.form.geometry),
        "radiusX" in state.annotation.data.form ? state.annotation.data.form.radiusX : null,
        "radiusY" in state.annotation.data.form ? state.annotation.data.form.radiusY : null,
        "tilt" in state.annotation.data.form ? state.annotation.data.form.tilt : null,
        "text" in state.annotation.data.form ? state.annotation.data.form.text : null,
        null,
        "TEXT",
        state.imageWithAnnotations.resource.imageAndQuery.image.id,
        null
      );

      if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
        await dispatch(
          createImageAnnotationSuccess({ keepAnnotating: false, cleanupCallback: cleanupCallback })
        );
      }
    }
  }
);

export interface CreateAnnotationSuccessParams {
  keepAnnotating: boolean;
  cleanupCallback: () => void;
}
export const createImageAnnotationSuccess = createAsyncThunk(
  "caseImageViewer/createImageAnnotationSuccess",
  async (params: CreateAnnotationSuccessParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      // NOTE: Placing of annotations continues until the user elects to stop for certain
      // annotation types like point, freehand, line
      if (!params.keepAnnotating) {
        dispatch(resetAnnotation());
      }
      await dispatch(
        refreshImage({ imageId: state.imageWithAnnotations.resource.imageAndQuery.image.id })
      );
      params.cleanupCallback();
    }
  }
);

export interface DeleteAnnotationParam {
  readonly annotationId: string;
  readonly deleteNested: boolean;
}

export const deleteAnnotation = createAsyncThunk(
  "caseImageViewer/deleteAnnotation",
  async (params: DeleteAnnotationParam, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseImageViewer;
    const response = await deleteAnnotationApi(params.annotationId, params.deleteNested);

    if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
      await dispatch(
        refreshImage({ imageId: state.imageWithAnnotations.resource.imageAndQuery.image.id })
      );
    }
    return response;
  }
);

export const caseImageViewerSlice = createSlice({
  name: "caseImageViewer",
  initialState: initialState,
  reducers: {
    adjustBrightness: (state, action: PayloadAction<number>) => {
      state.brightnessAdjust = action.payload;
    },
    adjustAnnotationOpacity: (state, action: PayloadAction<number>) => {
      state.annotationOpacity = action.payload;
    },
    toggleSidebarExpanded: state => {
      state.isSidebarExpanded = !state.isSidebarExpanded;
    },
    toggleMicroscropeActive: state => {
      state.isMicroscopeActive = !state.isMicroscopeActive;
    },
    toggleAnnotations: state => {
      state.hideAnnotations = !state.hideAnnotations;
    },
    // annotations
    beginAnnotation: (state, action: PayloadAction<BeginAnnotationParams>) => {
      state.annotation = {
        data: {
          form: null,
          status: AnnotationStatus.Placing,
          selectedAnnotationType: action.payload.selectedAnnotationType
        }
      };
    },
    setBeginDroppingAnnotation: (state, action: PayloadAction<BeginDroppingAnnotationParams>) => {
      state.annotation = {
        data: {
          form: null,
          status: AnnotationStatus.Dropping,
          selectedAnnotationType: {
            ...action.payload.selectedAnnotationType
          }
        } as AnnotationForm<typeof action.payload.selectedAnnotationType>
      };
      state.hpfCursorColor = (action.payload
        .selectedAnnotationType as any).hpfAnnotationClass.color;
    },
    setImageAnnotationForEllipseType: (
      state,
      action: PayloadAction<SetImageAnnotationForEllipseTypeParams>
    ) => {
      if (state.annotation.data) {
        state.annotation.data = {
          form: {
            geometry: action.payload.point,
            radiusX: action.payload.radiusX,
            radiusY: action.payload.radiusY,
            tilt: action.payload.tilt,
            text:
              state.annotation.data.form && "text" in state.annotation.data.form
                ? state.annotation.data.form.text
                : null
          },
          status: AnnotationStatus.Editing,
          selectedAnnotationType: {
            type: AnnotationType.Ellipse
          }
        } as AnnotationForm<EllipseAnnotationType>;
      }
    },
    setImageAnnotationForHPFType: (
      state,
      action: PayloadAction<SetImageAnnotationForHPFTypeParams>
    ) => {
      if (state.annotation.data) {
        state.annotation.data = {
          form: {
            geometry: action.payload.point,
            radiusX: action.payload.radius,
            text:
              state.annotation.data.form && "text" in state.annotation.data.form
                ? state.annotation.data.form.text
                : null
          },
          status: AnnotationStatus.Editing,
          selectedAnnotationType: {
            type: AnnotationType.HPF,
            hpfAnnotationClass: (state.annotation.data.selectedAnnotationType as HpfAnnotationType)
              .hpfAnnotationClass
          }
        } as AnnotationForm<HpfAnnotationType>;
      }
    },
    setImageAnnotationForTextType: (state, action: PayloadAction<LatLngLiteral>) => {
      if (state.annotation.data) {
        state.annotation.data = {
          form: {
            geometry: action.payload,
            text:
              state.annotation.data.form && "text" in state.annotation.data.form
                ? state.annotation.data.form.text
                : null
          },
          status: AnnotationStatus.Editing,
          selectedAnnotationType: {
            type: AnnotationType.Text
          }
        } as AnnotationForm<TextAnnotationType>;
      }
    },
    setImageAnnotationText: (state, action: PayloadAction<string>) => {
      if (state.annotation.data && state.annotation.data.form) {
        state.annotation = {
          data: {
            ...state.annotation.data,
            form: {
              ...state.annotation.data.form,
              text: action.payload
            }
          }
        };
      }
    },
    setImageAnnotationHighlight: (state, action: PayloadAction<string>) => {
      state.allHighlightedAnnotations = castDraft(
        highlightAll(state.imageWithAnnotations, [...state.highlightedAnnotations, action.payload])
      );
      state.highlightedAnnotations = castDraft(addId(state.highlightedAnnotations, action.payload));
    },
    setImageAnnotationUnhighlight: (state, action: PayloadAction<string>) => {
      state.allHighlightedAnnotations = castDraft(
        highlightAll(
          state.imageWithAnnotations,
          removeId(state.highlightedAnnotations, action.payload)
        )
      );
      state.highlightedAnnotations = castDraft(
        removeId(state.highlightedAnnotations, action.payload)
      );
    },
    cancelImageAnnotation: state => {
      state.annotation = initialState.annotation;
    },
    resetAnnotation: state => {
      state.annotation = initialState.annotation;
    },
    setSelectedMultilineClass: (state, action: PayloadAction<MultilineAnnotationClass | null>) => {
      state.selectedMultilineClass = action.payload;
    },
    setSelectedMultilineCategory: (state, action: PayloadAction<MultilineCategory | null>) => {
      state.selectedMultilineCategory = action.payload;
    },
    toggleHpfVisibility: (state, action: PayloadAction<HpfVisibilityPayload>) => {
      const anno = action.payload.fragment;
      const hpfVisibility = action.payload.hpfVisibility;
      //check if all hpfs for the class are being hidden
      const hpfClassHidden =
        state.hpfVisibility.findIndex(
          x =>
            x.hpfClassid === hpfVisibility.hpfClassid && x.hpfId === null && x.pointClassId === null
        ) !== -1;
      if (hpfClassHidden && hpfVisibility.hpfId === null && hpfVisibility.pointClassId === null) {
        //show hpfs by class
        state.hpfVisibility = state.hpfVisibility.filter(
          x => x.hpfClassid !== hpfVisibility.hpfClassid
        );
      } else {
        //check if the HPF is being hidden by ID
        const hpfIdHidden =
          state.hpfVisibility.findIndex(
            x =>
              x.hpfClassid === hpfVisibility.hpfClassid &&
              x.hpfId === hpfVisibility.hpfId &&
              x.pointClassId === null
          ) !== -1;
        if ((hpfClassHidden || hpfIdHidden) && hpfVisibility.pointClassId === null) {
          //check if entire hpf class is being hidden
          if (hpfClassHidden) {
            //show the class but hide all of the hpf ids under it
            state.hpfVisibility = state.hpfVisibility.filter(
              x => x.hpfClassid !== hpfVisibility.hpfClassid
            );
            anno.hpfs?.flatMap(hpf => {
              state.hpfVisibility = [
                ...state.hpfVisibility,
                {
                  hpfClassid: hpfVisibility.hpfClassid,
                  hpfId: hpf.id,
                  pointClassId: null
                }
              ];
            });
          }

          //show hpf by id
          state.hpfVisibility = state.hpfVisibility.filter(
            x => x.hpfClassid !== hpfVisibility.hpfClassid || x.hpfId !== hpfVisibility.hpfId
          );
        } else {
          //check if the points are being hidden by their class
          const pointClassHidden =
            state.hpfVisibility.findIndex(
              x =>
                x.hpfClassid === hpfVisibility.hpfClassid &&
                x.hpfId === hpfVisibility.hpfId &&
                x.pointClassId === hpfVisibility.pointClassId
            ) !== -1;
          if (hpfClassHidden || hpfIdHidden || pointClassHidden) {
            //check if entire hpf class has been hidden
            if (hpfClassHidden) {
              //show the class but hide all of the hpf ids under it
              state.hpfVisibility = state.hpfVisibility.filter(
                x => x.hpfClassid !== hpfVisibility.hpfClassid
              );
              anno.hpfs?.flatMap(hpf => {
                state.hpfVisibility = [
                  ...state.hpfVisibility,
                  {
                    hpfClassid: hpfVisibility.hpfClassid,
                    hpfId: hpf.id,
                    pointClassId: null
                  }
                ];
              });

              //show the hpf but hide all of the point classes under it
              state.hpfVisibility = state.hpfVisibility.filter(
                x => x.hpfId !== hpfVisibility.hpfId
              );
              const hpf = anno.hpfs?.find(x => x.id === hpfVisibility.hpfId);
              hpf?.points?.flatMap(point => {
                state.hpfVisibility = [
                  ...state.hpfVisibility,
                  {
                    hpfClassid: hpfVisibility.hpfClassid,
                    hpfId: hpf.id,
                    pointClassId: point.id
                  }
                ];
              });
            }

            //check if entire HPF has been hidden
            if (hpfIdHidden) {
              //show the hpf but hide all of the point classes under it
              state.hpfVisibility = state.hpfVisibility.filter(
                x => x.hpfId !== hpfVisibility.hpfId
              );
              const hpf = anno.hpfs?.find(x => x.id === hpfVisibility.hpfId);
              hpf?.points?.flatMap(point => {
                state.hpfVisibility = [
                  ...state.hpfVisibility,
                  {
                    hpfClassid: hpfVisibility.hpfClassid,
                    hpfId: hpf.id,
                    pointClassId: point.id
                  }
                ];
              });
            }

            //show points by class
            state.hpfVisibility = state.hpfVisibility.filter(
              x =>
                x.hpfClassid !== hpfVisibility.hpfClassid ||
                x.hpfId !== hpfVisibility.hpfId ||
                x.pointClassId !== hpfVisibility.pointClassId
            );
          } else {
            //hide selected
            state.hpfVisibility = [...state.hpfVisibility, hpfVisibility];
          }
        }
      }
    },
    toggleCeliacTableVisibility: state => {
      state.celiacTableVisibility = !state.celiacTableVisibility;
    }
  },
  extraReducers: builder => {
    // caseFetch
    builder.addCase(caseFetch.pending, state => {
      state.histoCase = { isPending: true };
      state.imageWithAnnotations = null;
    });
    builder.addCase(caseFetch.fulfilled, (state, action) => {
      state.histoCase = { resource: castDraft(action.payload) };
    });
    builder.addCase(caseFetch.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // refreshCase
    builder.addCase(refreshCase.fulfilled, (state, action) => {
      const selectedImageId =
        state.imageWithAnnotations && "resource" in state.imageWithAnnotations
          ? state.imageWithAnnotations.resource.imageAndQuery.image.id
          : null;

      const isSelectedImageInCase =
        selectedImageId !== null &&
        action.payload &&
        action.payload.images.map(image => image.id).includes(selectedImageId);

      if (action.payload) {
        state.histoCase = { resource: castDraft(action.payload) };
      }
      state.imageWithAnnotations = isSelectedImageInCase ? state.imageWithAnnotations : null;
      state.allHighlightedAnnotations = castDraft(highlightAll(state.imageWithAnnotations, []));
    });
    builder.addCase(refreshCase.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // refreshImage
    builder.addCase(refreshImage.fulfilled, (state, action) => {
      state.imageWithAnnotations = { resource: castDraft(action.payload) };
      state.allHighlightedAnnotations = castDraft(highlightAllImage(action.payload, []));
    });
    builder.addCase(refreshImage.rejected, (state, action) => {
      state.imageWithAnnotations = { errorMessage: action.error.message || "" };
    });

    // transitionCaseStatus
    builder.addCase(transitionCaseStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.histoCase = { resource: castDraft(action.payload) };
      }
    });
    builder.addCase(transitionCaseStatus.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // selectImage
    builder.addCase(selectImage.pending, state => {
      state.queryEditor = initialState.queryEditor;
    });

    builder.addCase(imageFetch.pending, state => {
      state.imageWithAnnotations = { isPending: true };
    });
    builder.addCase(imageFetch.fulfilled, (state, action) => {
      state.imageWithAnnotations = { resource: castDraft(action.payload) };
      state.allHighlightedAnnotations = castDraft(highlightAllImage(action.payload, []));
    });
    builder.addCase(toggleAcceptAiAnnotations.fulfilled, (state, action) => {
      /*const newAcceptAi = castDraft(action.payload).acceptAiAnnotations;
      if (state && state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
        state.imageWithAnnotations = {
          ...state.imageWithAnnotations,
          resource: {
            ...state.imageWithAnnotations.resource,
            acceptAiAnnotations: newAcceptAi
          }
        };
      }*/
      const { acceptAiAnnotations } = action.payload;

      if ((state?.imageWithAnnotations as any)?.resource) {
        (state.imageWithAnnotations as any).resource.acceptAiAnnotations = acceptAiAnnotations;
      }
    });
    builder.addCase(imageFetch.rejected, (state, action) => {
      state.imageWithAnnotations = { errorMessage: action.error.message || "" };
    });

    // hideImageForReader
    builder.addCase(toggleImageForCR.pending, state => {
      state.imageWithAnnotations = { isPending: true };
    });
    builder.addCase(toggleImageForCR.rejected, (state, action) => {
      state.imageWithAnnotations = { errorMessage: action.error.message || "" };
    });

    // queries

    // updateCaseComment
    builder.addCase(updateCaseComment.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // updateImageComment
    builder.addCase(updateImageComment.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // openQuery
    builder.addCase(openQuery.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // closeQuery
    builder.addCase(closeQuery.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // unresolvableQuery
    builder.addCase(unresolvableQuery.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // newQueryReminder
    builder.addCase(newQueryReminder.rejected, (state, action) => {
      state.histoCase = { errorMessage: action.error.message || "" };
    });

    // annotations

    // deleteAnnotation
    builder.addCase(deleteAnnotation.fulfilled, state => {
      state.annotation = initialState.annotation;
    });
    builder.addCase(deleteAnnotation.rejected, (state, action) => {
      state.annotation = {
        data: null,
        errorMessage: action.error.message || ""
      };
    });

    // createHpfImageAnnotation
    builder.addCase(createHpfImageAnnotation.pending, (state, action) => {
      state.tempHpf = action.meta.arg.point;
    });
    builder.addCase(createHpfImageAnnotation.fulfilled, state => {
      if (state.imageWithAnnotations && "resource" in state.imageWithAnnotations) {
        state.tempHpf = null;
      }
    });
    builder.addCase(createHpfImageAnnotation.rejected, (state, action) => {
      state.annotation = {
        data: state.annotation.data,
        errorMessage: action.error.message || ""
      };
    });

    // createAnnotation
    builder.addCase(createAnnotation.rejected, (state, action) => {
      state.annotation = {
        data: state.annotation.data,
        errorMessage: action.error.message
      };
    });
  }
});

export const {
  adjustBrightness,
  adjustAnnotationOpacity,
  toggleSidebarExpanded,
  toggleMicroscropeActive,
  toggleAnnotations,
  beginAnnotation,
  setBeginDroppingAnnotation,
  setImageAnnotationForEllipseType,
  setImageAnnotationForHPFType,
  setImageAnnotationForTextType,
  setImageAnnotationText,
  setImageAnnotationHighlight,
  setImageAnnotationUnhighlight,
  cancelImageAnnotation,
  resetAnnotation,
  setSelectedMultilineClass,
  setSelectedMultilineCategory,
  toggleHpfVisibility,
  toggleCeliacTableVisibility
} = caseImageViewerSlice.actions;

export default caseImageViewerSlice.reducer;
