import React from "react";

import { CaseWithImages, ImageAndQuery, QueryDetails, QueryObjectType } from "../models";
import { LoggedInUser, RolePermissions } from "../permissions";
import { Box, Heading } from "@blasterjs/core";
import { CollapseIcon } from "./Expandable";
import { toggleSidebarExpanded } from "../slices/caseImageViewer";
import SidebarPanelCaseDetails from "./SidebarPanelCaseDetails";
import QueryList from "./QueryList";
import { useAppDispatch } from "../hooks";

interface Props {
  readonly histoCase: CaseWithImages | null;
  readonly selectedImage: ImageAndQuery | null;
  readonly queryDetails: QueryDetails;
  readonly currentViewQueryObjectType: QueryObjectType;
  readonly loggedInUser: LoggedInUser;
}

const SidebarPanelQueries = ({
  histoCase,
  selectedImage,
  queryDetails,
  currentViewQueryObjectType,
  loggedInUser
}: Props) => {
  const dispatch = useAppDispatch();

  const collapseIcon = () => <CollapseIcon onToggle={() => dispatch(toggleSidebarExpanded())} />;

  return (
    <>
      <Box display="flex" width="100%" mb={2}>
        <Box textAlign="left">
          <Heading as="h5">Queries</Heading>
        </Box>
        <Box ml={"auto"}>{collapseIcon()}</Box>
      </Box>

      <SidebarPanelCaseDetails
        loggedInUser={loggedInUser}
        histoCase={histoCase}
        selectedImage={selectedImage}
      />

      {loggedInUser.can([RolePermissions.AP_CaseViewer_ViewQueries]) ? (
        <QueryList
          histoCase={histoCase}
          selectedImage={selectedImage}
          queryObjectType={currentViewQueryObjectType}
          loggedInUser={loggedInUser}
          queryDetails={queryDetails}
        />
      ) : null}
    </>
  );
};

export default SidebarPanelQueries;
