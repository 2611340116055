/* eslint-disable functional/immutable-data */
import React, { useEffect, useState } from "react";
import { Appearance, Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import {
  ImageManagementCaseRecord,
  formatCaseStatus,
  CaseStatus,
  ImageManagementTableColumn,
  TableColumnVisibilityObject
} from "../models";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableFilters } from "../components/Table";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fetchImageManagementCasesTabCsv,
  fetchImageManagementImagesCaseResults,
  openCaseFilterDialog,
  setCaseResultsFilters,
  countCaseFilters
} from "../slices/imageManagement";
import { useNavigate } from "react-router";
import Timestamp from "../components/Timestamp";
import Avatar from "react-avatar";
import ImageManagementBulkCaseDialog from "../components/ImageManagementBulkCaseDialog";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import ImageManagementCaseDialogParent from "../components/ImageManagementCaseDialogParent";
import {
  CaseRowOptions,
  openImageManagementCaseDialogParent
} from "../slices/ImageManagementCaseDialogParent";
import ImageManagementEditTableCellDialog from "../components/ImageManagementEditTableCellDialog";
import { RolePermissions } from "../permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChevronDown,
  faPencil,
  faTableColumns
} from "@fortawesome/free-solid-svg-icons";
import {
  CaseTableColumns,
  openImageManagementEditTableCellDialog
} from "../slices/ImageManagementEditTableCell";
import CaseSearchFilters from "../components/ImageManagementCaseSearchFilters";
import {
  generateTableColumnVisibility,
  SubNavLink,
  TableButton,
  WideTableContainer
} from "../pages/ImageManagement";
import ImageManagementShowHideColumns, {
  CASES_TABLE_VISIBILITY_COOKIE_NAME,
  caseTableDefaultColumnVisibility,
  caseTableDefaultRoleColumnVisibility
} from "./ImageManagementShowHideColumns";

const CaseResults = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const caseRecords = useAppSelector(state => state.imageManagement.caseRecords);
  const isCaseFilterOpen = useAppSelector(state => state.imageManagement.isCaseFilterOpen);
  const searchFilters = useAppSelector(state => state.imageManagement.caseResultFilters);

  useEffect(() => {
    dispatch(fetchImageManagementImagesCaseResults());
    setVisibleColumns(
      generateTableColumnVisibility(
        caseTableDefaultColumnVisibility,
        caseTableDefaultRoleColumnVisibility,
        loggedInUser,
        CASES_TABLE_VISIBILITY_COOKIE_NAME
      )
    );
  }, [searchFilters, loggedInUser]);

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  //const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [caseHideShowColumnDialog, setCaseHideShowColumnDialog] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState<TableColumnVisibilityObject>(
    generateTableColumnVisibility(
      caseTableDefaultColumnVisibility,
      caseTableDefaultRoleColumnVisibility,
      loggedInUser,
      CASES_TABLE_VISIBILITY_COOKIE_NAME
    )
  );

  const error =
    "errorMessage" in caseRecords ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{caseRecords.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const columns: Array<object> = [];

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewHistopathologyProcedureID]) &&
    visibleColumns[ImageManagementTableColumn.HistoProcedureID]?.visible
  ) {
    columns.push({
      name: "Histo Procedure ID",
      width: "25rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.histoProcedureId || "",
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          <div title={imageRecord.histoProcedureId || ""}>
            {"resource" in loggedInUser &&
              loggedInUser.resource.can([
                RolePermissions.AP_CaseData_EditHistopathologyProcedureID
              ]) && (
                <span
                  style={{ cursor: "pointer", color: "#9A9DA0" }}
                  onClick={() =>
                    dispatch(
                      openImageManagementEditTableCellDialog({
                        editingColumn: CaseTableColumns.HistoProcedureID,
                        row: imageRecord,
                        columnValue: imageRecord.histoProcedureId,
                        errorMessage: null
                      })
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPencil} />
                </span>
              )}
            &nbsp;{imageRecord.histoProcedureId}
          </div>
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewEndoProcedureID]) &&
    visibleColumns[ImageManagementTableColumn.EndoProcedureID]?.visible
  ) {
    columns.push({
      name: "Endo Procedure ID",
      width: "25rem",
      wrap: true,
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.procId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.procId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditEndoProcedureID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.EndoProcedureID,
                      row: imageRecord,
                      columnValue: imageRecord.procId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.procId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_StudyData_ViewStudyName]) &&
    visibleColumns[ImageManagementTableColumn.StudyName]?.visible
  ) {
    columns.push({
      name: "Study Name",
      sortable: true,
      wrap: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.studyName,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.studyName || ""}>{imageRecord.studyName}</div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSiteID]) &&
    visibleColumns[ImageManagementTableColumn.SiteID]?.visible
  ) {
    columns.push({
      name: "Site ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.siteId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.siteId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSiteID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.SiteID,
                      row: imageRecord,
                      columnValue: imageRecord.siteId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.siteId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSubjectID]) &&
    visibleColumns[ImageManagementTableColumn.SubjectID]?.visible
  ) {
    columns.push({
      name: "Subject ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.subjectId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.subjectId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSubjectID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.SubjectID,
                      row: imageRecord,
                      columnValue: imageRecord.subjectId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.subjectId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID]) &&
    visibleColumns[ImageManagementTableColumn.VisitID]?.visible
  ) {
    columns.push({
      name: "Visit ID",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.visitId,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div title={imageRecord.visitId || ""}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditVisitID]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.VisitID,
                      row: imageRecord,
                      columnValue: imageRecord.visitId,
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.visitId}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    visibleColumns[ImageManagementTableColumn.CaseDateCreated]?.visible
  ) {
    columns.push({
      name: "Date Created",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.caseCreatedAt,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <Timestamp date={imageRecord.caseCreatedAt} />
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewExpectedNoofimages]) &&
    visibleColumns[ImageManagementTableColumn.ImagesExpected]?.visible
  ) {
    columns.push({
      name: "Images Expected",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.imagesExpected,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditExpectedNoofImages]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.ImagesExpected,
                      row: imageRecord,
                      columnValue: imageRecord.imagesExpected.toString(),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.imagesExpected}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewNoofimagesReceived]) &&
    visibleColumns[ImageManagementTableColumn.ImagesReceived]?.visible
  ) {
    columns.push({
      name: "Images Received",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.totalImages,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_CaseData_EditNoofImagesReceived]) && (
              <span
                style={{ cursor: "pointer", color: "#9A9DA0" }}
                onClick={() =>
                  dispatch(
                    openImageManagementEditTableCellDialog({
                      editingColumn: CaseTableColumns.ImagesReceived,
                      row: imageRecord,
                      columnValue: imageRecord.totalImages.toString(),
                      errorMessage: null
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
            )}
          &nbsp;{imageRecord.totalImages}
        </div>
      )
    });
  }

  if (
    "resource" in loggedInUser &&
    visibleColumns[ImageManagementTableColumn.CaseStatus]?.visible
  ) {
    columns.push({
      name: "Status",
      // wait for 2.2 width: "16rem",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.status,
      format: (imageRecord: ImageManagementCaseRecord) => (
        <div>{formatCaseStatus(imageRecord.status)}&nbsp;</div>
      )
    });
  }

  if ("resource" in loggedInUser && visibleColumns[ImageManagementTableColumn.Queries]?.visible) {
    columns.push({
      name: "Queries",
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.openQueries,
      sortable: true
    });
  }

  if ("resource" in loggedInUser && visibleColumns[ImageManagementTableColumn.LabTechQC]?.visible) {
    columns.push({
      name: "Lab Tech QC",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.labTech,
      format: (imageRecord: ImageManagementCaseRecord) => {
        return imageRecord.labTech.map(user => (
          <Avatar
            key={user.username}
            name={user.username}
            alt={user.username}
            size={"20"}
            round={true}
            textMarginRatio={0.1}
            textSizeRatio={30 / 14}
            style={{
              fontFamily: "InterVariable",
              zIndex: 100,
              position: "relative"
            }}
          />
        ));
      }
    });
  }

  if ("resource" in loggedInUser && visibleColumns[ImageManagementTableColumn.ISCQC]?.visible) {
    columns.push({
      name: "ISC QC",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.iscQc,
      format: (imageRecord: ImageManagementCaseRecord) => {
        return imageRecord.iscQc.map(user => (
          <Avatar
            key={user.username}
            name={user.username}
            alt={user.username}
            size={"20"}
            round={true}
            textMarginRatio={0.1}
            textSizeRatio={30 / 14}
            style={{
              fontFamily: "InterVariable",
              zIndex: 100,
              position: "relative"
            }}
          />
        ));
      }
    });
  }

  if (
    "resource" in loggedInUser &&
    visibleColumns[ImageManagementTableColumn.CRAssignment]?.visible
  ) {
    columns.push({
      name: "CR Assignment",
      sortable: true,
      selector: (imageRecord: ImageManagementCaseRecord) => imageRecord.crAssignments,
      format: (imageRecord: ImageManagementCaseRecord) => {
        return imageRecord.crAssignments.map(username => (
          <Avatar
            key={username}
            name={username}
            alt={username}
            size={"20"}
            round={true}
            textMarginRatio={0.1}
            textSizeRatio={30 / 14}
            style={{
              fontFamily: "InterVariable",
              zIndex: 100,
              position: "relative"
            }}
          />
        ));
      }
    });
  }

  columns.push({
    right: true,
    grow: 1,
    cell: (imageRecord: ImageManagementCaseRecord) => (
      <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
        <Icon name="menu" />

        <TooltipFlyout placement={"bottom"}>
          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ChangeStatus]) &&
            imageRecord.status != CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.RevertStatus,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Revert Status
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_PutoHold]) &&
            imageRecord.status == CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.RemoveHold,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Remove Hold
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_PutoHold]) &&
            imageRecord.status != CaseStatus.OnHold && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.PutOnHold,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Put on Hold
              </TooltipLink>
            )}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewimageViewer]) && (
              <TooltipLink
                onClick={() =>
                  navigate(`/studies/${imageRecord.studyId}/cases/${imageRecord.caseId}`)
                }
              >
                View Images
              </TooltipLink>
            )}

          {/* {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_DownloadImages]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.Download,
                      caseId: imageRecord.caseId
                    })
                  )
                }
              >
                Download
              </TooltipLink>
            )} */}

          {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ArchiveCase]) && (
              <TooltipLink
                onClick={() =>
                  dispatch(
                    openImageManagementCaseDialogParent({
                      caseRowOption: CaseRowOptions.Archive,
                      caseId: imageRecord.caseId,
                      contextInfo: `${imageRecord.histoProcedureId || ""}:${imageRecord.procId}`
                    })
                  )
                }
              >
                Archive Case
              </TooltipLink>
            )}
        </TooltipFlyout>
      </Button>
    )
  });

  // const onSelectedRowChange = (selected: {
  //   allSelected: boolean;
  //   selectedCount: number;
  //   selectedRows: object[];
  // }) => {
  //   const rows = selected.selectedRows as Array<ImageManagementCaseRecord>;
  //   const caseIds = rows.map(m => m.caseId || "");
  //   setSelectedRows(caseIds);
  // };

  const viewCase = (record: ImageManagementCaseRecord) => {
    navigate(`/studies/${record.studyId}/cases/${record.caseId}`);
  };

  const exportReportCase = () => {
    dispatch(fetchImageManagementCasesTabCsv());
  };

  const exportReportButtonCase =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ExportCaseLevelReport]) ? (
      <TableButton
        iconBefore="download"
        onClick={exportReportCase}
        style={{ marginRight: "0.5rem" }}
      >
        Export Report
      </TableButton>
    ) : null;

  const showHideColumnsButton = (
    <TableButton onClick={() => setCaseHideShowColumnDialog(true)}>
      <FontAwesomeIcon icon={faTableColumns} style={{ marginRight: "5px", color: "#9A9DA0" }} />
      Show/Hide Columns&nbsp;{" "}
      {Object.values(visibleColumns).filter(column => !column.visible).length > 0 ? (
        <b style={{ fontWeight: 700 }}>
          ({Object.values(visibleColumns).filter(column => !column.visible).length})
        </b>
      ) : null}
      <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "5px", color: "#3D3E40" }} />
    </TableButton>
  );
  const numCaseFilters = countCaseFilters(searchFilters);

  const content = error ? (
    error
  ) : (
    <WideTableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={""}
            placeholder={"Search"}
            onValueChange={name =>
              dispatch(setCaseResultsFilters({ ...searchFilters, searchText: name }))
            }
          />
          <Box paddingLeft="30px" paddingTop="6px">
            <SubNavLink onClick={() => dispatch(openCaseFilterDialog())}>
              <Icon name="plus" /> Add Filter {numCaseFilters == 0 ? "" : `(${numCaseFilters})`}
            </SubNavLink>
          </Box>
          <Box ml="auto">
            <span
              style={{ marginRight: "2rem", cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              <FontAwesomeIcon icon={faArrowsRotate} style={{ color: "#9A9DA0" }} />
            </span>
            {showHideColumnsButton}
            {exportReportButtonCase}
          </Box>
        </Box>
      </TableFilters>
      {"resource" in caseRecords && (
        <StyledDataTable
          columns={columns}
          data={caseRecords.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
          // selectableRows
          // onSelectedRowsChange={onSelectedRowChange}
          fixedHeader={true}
          onRowClicked={viewCase}
        />
      )}
    </WideTableContainer>
  );

  return (
    <>
      <CaseSearchFilters isOpen={isCaseFilterOpen} />
      <ImageManagementBulkCaseDialog />
      <ImageManagementCaseDialogParent />
      <ImageManagementEditTableCellDialog />
      {"resource" in loggedInUser && (
        <ImageManagementShowHideColumns
          columns={visibleColumns}
          setColumns={setVisibleColumns}
          isDialogOpen={caseHideShowColumnDialog}
          setOpenDialog={setCaseHideShowColumnDialog}
          cookieName={CASES_TABLE_VISIBILITY_COOKIE_NAME}
          loggedInUser={loggedInUser.resource}
        />
      )}
      {content}
      {/* {selectedRows && selectedRows.length > 0 && "resource" in loggedInUser && (
        <BulkOperationToolbar
          tab="case"
          loggedInUser={loggedInUser.resource}
          selectedCount={selectedRows.length}
        />
      )} */}
    </>
  );
};

export default CaseResults;
