import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignedURLs } from "../models";
import { WriteResource } from "../types";
import { CancelTokenSource } from "axios";
import { createMldFileSignedURLs, generateCancelTokenSource, uploadMldFiles } from "../api";
import { AppDispatch, RootState } from "../store";
import { castDraft } from "immer";
import { fetchImageManagementAnnotationTabResults } from "./imageManagement";

interface FileUploadProgress {
  readonly file: File;
  readonly progress: number;
}

interface AllFileUploadProgress {
  // NOTE: key is the filename
  readonly [key: string]: FileUploadProgress;
}

interface InProgressUpload {
  readonly uploadProgress: AllFileUploadProgress;
  readonly cancelTokenSource: CancelTokenSource;
}

export interface ImportAnnotationsDialogState {
  readonly isOpen: boolean;
  readonly acceptButtonDisabled: boolean;
  readonly fileSignedURLs: WriteResource<FileList | null, SignedURLs>;
  readonly currentUpload: InProgressUpload | null;
  readonly errorMessage: string | null;
}

export const initialState: ImportAnnotationsDialogState = {
  isOpen: false,
  acceptButtonDisabled: false,
  fileSignedURLs: {
    data: null,
    resource: []
  },
  currentUpload: null,
  errorMessage: null
};

export interface UploadImageProgressParams {
  readonly file: File;
  readonly progress: number;
}

export const uploadAnnotationsProgressDispatchFile: (
  dispatch: AppDispatch,
  file: File
) => (progressEvent: ProgressEvent) => void = (dispatch: AppDispatch, file: File) => {
  return (progressEvent: ProgressEvent) =>
    dispatchUploadAnnotationsProgress(dispatch, file, progressEvent);
};

export const dispatchUploadAnnotationsProgress = (
  dispatch: AppDispatch,
  file: File,
  progressEvent: ProgressEvent
) => {
  dispatch(uploadAnnotationsProgress({ file: file, progress: progressEvent.loaded }));
};

// thunks
export const signedURLsCreate = createAsyncThunk(
  "importAnnotationsDialog/signedURLsCreate",
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;

    const signedURLsCreateInnerResponse = await dispatch(signedURLsCreateInner());
    if (signedURLsCreateInnerResponse) {
      await dispatch(uploadAnnotations(generateCancelTokenSource()));
    }
  }
);
export const signedURLsCreateInner = createAsyncThunk(
  "importAnnotationsDialog/signedURLsCreateInner",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (
      "data" in state.importAnnotationsDialog.fileSignedURLs &&
      state.importAnnotationsDialog.fileSignedURLs.data !== null
    ) {
      const createSignedUrlsResponse = await createMldFileSignedURLs(
        state.importAnnotationsDialog.fileSignedURLs.data
      );

      return createSignedUrlsResponse;
    }
  }
);

export const uploadAnnotations = createAsyncThunk(
  "importAnnotationsDialog/uploadFiles",
  async (cancelTokenSource: CancelTokenSource, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.importAnnotationsDialog;

    if (
      "data" in state.fileSignedURLs &&
      state.fileSignedURLs.data !== null &&
      "resource" in state.fileSignedURLs
    ) {
      dispatch(uploadAnnotationsPending(cancelTokenSource));

      const uploadFilesResponse = await uploadMldFiles(
        state.fileSignedURLs.data,
        state.fileSignedURLs.resource,
        uploadAnnotationsProgressDispatchFile,
        cancelTokenSource
      );

      if (state.fileSignedURLs.data && !("errorMessage" in state.fileSignedURLs.resource)) {
        await dispatch(fetchImageManagementAnnotationTabResults());
      }

      return uploadFilesResponse;
    }
  }
);

export const importAnnotationsDialogSlice = createSlice({
  name: "importAnnotationsDialog",
  initialState: initialState,
  reducers: {
    openImportAnnotationsDialog: state => {
      state.isOpen = true;
      state.errorMessage = null;
    },
    closeImportAnnotationsDialog: state => {
      state.isOpen = false;
    },
    changeFiles: (state, action: PayloadAction<FileList | null>) => {
      state.fileSignedURLs.data = action.payload;
    },
    uploadAnnotationsProgress: (state, action: PayloadAction<UploadImageProgressParams>) => {
      if (state.currentUpload !== null) {
        state.currentUpload = {
          uploadProgress: {
            ...state.currentUpload.uploadProgress,
            [action.payload.file.name]: {
              file: action.payload.file,
              progress: action.payload.progress
            }
          },
          cancelTokenSource: state.currentUpload.cancelTokenSource
        };
      }
    },
    // eslint-disable-next-line
    uploadAnnotationsPending: (state, action) => {
      if (
        "data" in state.fileSignedURLs &&
        state.fileSignedURLs.data !== null &&
        "resource" in state.fileSignedURLs
      ) {
        state.fileSignedURLs = {
          data: state.fileSignedURLs.data,
          resource: state.fileSignedURLs.resource,
          isPending: true
        };
        state.currentUpload = {
          uploadProgress: Array.from(state.fileSignedURLs.data as FileList).reduce(
            (acc: AllFileUploadProgress, file: File) => ({
              ...acc,
              [file.name]: {
                file,
                progress: 0
              }
            }),
            {}
          ),
          cancelTokenSource: action.payload.cancelTokenSource
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(signedURLsCreateInner.pending, state => {
      state.fileSignedURLs = {
        data: state.fileSignedURLs.data,
        isPending: true
      };
    });
    builder.addCase(signedURLsCreateInner.fulfilled, (state, action) => {
      if (action.payload && action.payload !== undefined) {
        state.fileSignedURLs = {
          data: state.fileSignedURLs.data,
          resource: castDraft(action.payload)
        };
      }
    });
    builder.addCase(signedURLsCreateInner.rejected, (state, action) => {
      state.fileSignedURLs = {
        data: state.fileSignedURLs.data,
        errorMessage: action.error.message
      };
      if (action.error.message) {
        state.errorMessage = action.error.message.toString();
      }
    });
    builder.addCase(uploadAnnotations.fulfilled, state => {
      state.isOpen = "errorMessage" in state.fileSignedURLs;
      state.currentUpload = null;
      state.fileSignedURLs = { data: null };
    });
  }
});

export const {
  openImportAnnotationsDialog,
  closeImportAnnotationsDialog,
  changeFiles,
  uploadAnnotationsProgress,
  uploadAnnotationsPending
} = importAnnotationsDialogSlice.actions;

export default importAnnotationsDialogSlice.reducer;
