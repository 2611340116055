import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Indication } from "../models";
import { getHpfRadiusInMeters } from "../utils";
import { useMapInstance } from "./ImageViewer";

const MicroscopeMaskStyled = styled.svg`
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: absolute;
  pointer-events: none;
  display: block;
`;

interface Props {
  readonly pixelSize: number;
  readonly resolutionInMeters: number;
  readonly indications: ReadonlyArray<Indication>;
}

const MicroscopeMask = ({ indications, pixelSize, resolutionInMeters }: Props) => {
  const map = useMapInstance();
  useEffect(() => {
    const getRadiusInPixels = (map: L.Map) => {
      const radiusInMeters = getHpfRadiusInMeters(pixelSize, resolutionInMeters, indications);

      // Need to convert the radius from map units to pixels, see:
      // https://stackoverflow.com/a/27546312
      const centerLatLng = map.getCenter();
      const pointC = map.latLngToContainerPoint(centerLatLng);
      const latLngC = map.containerPointToLatLng(pointC);
      const latLngX = map.containerPointToLatLng([pointC.x + 1, pointC.y]);
      const distanceX = latLngC.distanceTo(latLngX);
      return radiusInMeters / distanceX;
    };

    const onZoom = (e: L.LeafletEvent) => {
      setZoom(e.target._animateToZoom);
    };

    const initializeMask = (map: L.Map) => {
      const zoom = map.getZoom();
      setZoom(zoom);
      map.on("zoomanim", onZoom);

      // This is how refs work, so mutation can't be avoided
      // eslint-disable-next-line functional/immutable-data
      initialZoomAndRadiusRef.current = [zoom, getRadiusInPixels(map)];
    };
    map && initializeMask(map);
    return () => {
      map && map.off("zoomanim", onZoom);
    };
  }, [map, indications, pixelSize, resolutionInMeters]);

  // The stategy for adjusting the microscope circle radius is to store the initial
  // zoom level and calculate the initial radius in pixels. Then an SVG transform of
  // the circle is updated at the start of each zoom event, which will animate the
  // transition. It is done this way vs. just updating the radius each time for two
  // reasons:
  //  1) The way in which the radius is calculated makes use of the map itself to
  //     measure distance and convert to screen pixels. So we can't effectively use
  //     that calculation method until the map zoom has completed. But we want to do
  //     it at the start of the zoom in order to allow for a smooth transition.
  //  2) It seems using SVG CSS transitions tend to work much better when using the
  //     transform property, versus just updating the radius. I tested out several
  //     transformation techniques with the radius adjusting and it wasn't very
  //     consistent. I believe using the transform is more or less the sanctioned way
  //     of doing this.
  //
  // So the initial zoom and radius are stored in a ref as soon as we have access to
  // the map. And the zoom is then updated as a state variable at the start of each zoom
  // animation.
  const initialZoomAndRadiusRef = useRef<[number, number] | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);

  return zoom && initialZoomAndRadiusRef.current ? (
    <MicroscopeMaskStyled>
      <defs>
        <mask id="mask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          <circle
            id="microscope-circle"
            cx="50%"
            cy="50%"
            r={initialZoomAndRadiusRef.current[1]}
            transform={`scale(${Math.pow(2, zoom - initialZoomAndRadiusRef.current[0])})`}
          />
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="black" opacity="70%" mask="url(#mask)" />
    </MicroscopeMaskStyled>
  ) : null;
};

export default MicroscopeMask;
