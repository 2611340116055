/* eslint-disable functional/immutable-data */
import React, { useEffect } from "react";
import { Box, Button, Callout, Icon, Intent, Text, Select } from "@blasterjs/core";
import {
  formatFileSize,
  formatMldStatus,
  ImageManagementAnnotationRecord,
  MldStatus
} from "../models";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableFilters } from "../components/Table";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  fetchImageManagementAnnotationTabResults,
  setAnnotationResultsFilters,
  setAnnotationStatusFilter
} from "../slices/imageManagement";
import Timestamp from "../components/Timestamp";
import Avatar from "react-avatar";
import { RolePermissions } from "../permissions";
import { WideTableContainer } from "../pages/ImageManagement";
import { openImportAnnotationsDialog } from "../slices/importAnnotationsDialog";
import ImportAnnotationsDialog from "./ImportAnnotationsDialog";
import { mldStatusDecoder } from "../decoders";
import { Ok } from "ts.data.json";

const AnnotationResults = () => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const annotationRecords = useAppSelector(state => state.imageManagement.annotationRecords);
  const searchFilters = useAppSelector(state => state.imageManagement.annotationResultFilters);

  useEffect(() => {
    dispatch(fetchImageManagementAnnotationTabResults());
  }, [searchFilters]);

  const onSelectStatusFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mldStatusResult = mldStatusDecoder.decode(e.target.value);
    const selectedMldStatus = mldStatusResult instanceof Ok ? mldStatusResult.value : null;
    dispatch(setAnnotationStatusFilter(selectedMldStatus));
  };

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);

  const error =
    "errorMessage" in annotationRecords ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{annotationRecords.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const columns: Array<object> = [];

  columns.push({
    name: "Imported Annotations File",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) => annotationRecord.mldId || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.mldName || ""}>{annotationRecord.mldName}</div>
        {annotationRecord.mldFileLength ? (
          <div style={{ fontStyle: "italic", paddingRight: "10px" }}>
            {formatFileSize(annotationRecord.mldFileLength)}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  });

  function mldStatusIcon(status: MldStatus) {
    switch (status) {
      case MldStatus.Complete:
        return <Icon name="processSuccess" color="green" />;
      case MldStatus.Error:
        return <Icon name="query" color="red" />;
    }
  }

  columns.push({
    name: "Status",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) => annotationRecord.status || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.status || ""}>
          {mldStatusIcon(annotationRecord.status)}
          &nbsp;
          {formatMldStatus(annotationRecord.status)}
          {annotationRecord.status === MldStatus.Error ? `: ${annotationRecord.errorMessage}` : ""}
        </div>
      </div>
    )
  });

  columns.push({
    name: "APP Sequence",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) =>
      annotationRecord.appSequenceId || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.appSequenceId || ""}>
          &nbsp;{annotationRecord.appSequenceId}
        </div>
      </div>
    )
  });

  columns.push({
    name: "Layers",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) =>
      annotationRecord.numLayers || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.numLayers.toString() || ""}>
          &nbsp;{annotationRecord.numLayers}
        </div>
      </div>
    )
  });

  columns.push({
    name: "Image File",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) =>
      annotationRecord.imageName || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.imageName || ""}>&nbsp;{annotationRecord.imageName}</div>
      </div>
    )
  });

  columns.push({
    name: "Endo/Histo Procedure ID",
    wrap: true,
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) => annotationRecord.label || "",
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <div>
        <div title={annotationRecord.label || ""}>&nbsp;{annotationRecord.label}</div>
      </div>
    )
  });

  columns.push({
    name: "Import Date",
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) => annotationRecord.uploadedAt,
    format: (annotationRecord: ImageManagementAnnotationRecord) => (
      <Timestamp date={annotationRecord.uploadedAt} />
    )
  });

  columns.push({
    name: "User",
    sortable: true,
    selector: (annotationRecord: ImageManagementAnnotationRecord) => annotationRecord.uploadedBy,
    format: (annotationRecord: ImageManagementAnnotationRecord) => {
      return annotationRecord.uploadedBy ? (
        <Avatar
          key={annotationRecord.uploadedBy}
          name={annotationRecord.uploadedBy}
          alt={annotationRecord.uploadedBy}
          size={"20"}
          round={true}
          textMarginRatio={0.1}
          textSizeRatio={30 / 14}
          style={{
            fontFamily: "InterVariable",
            zIndex: 100,
            position: "relative"
          }}
        />
      ) : (
        ""
      );
    }
  });

  const importAnnotationsButton =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_AnnotationListTab_ImportAnnotations]) ? (
      <Button
        iconBefore="download"
        appearance="prominent"
        onClick={() => dispatch(openImportAnnotationsDialog())}
        intent="primary"
      >
        Import Annotations
      </Button>
    ) : null;

  const content = error ? (
    error
  ) : (
    <WideTableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="annotations-search"
            width="auto"
            defaultValue={""}
            placeholder={"Search"}
            onValueChange={name =>
              dispatch(setAnnotationResultsFilters({ ...searchFilters, searchText: name }))
            }
          />
          <Box paddingLeft="30px">
            <Select onChange={onSelectStatusFilter} selected="">
              <option key="" value="">
                All statuses
              </option>
              {Object.values(MldStatus).map(status => {
                return (
                  <option key={status} value={status}>
                    {formatMldStatus(status)}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Box ml="auto">{importAnnotationsButton}</Box>
        </Box>
      </TableFilters>
      {"resource" in annotationRecords && (
        <StyledDataTable
          columns={columns}
          data={annotationRecords.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
          fixedHeader={true}
        />
      )}
    </WideTableContainer>
  );
  return (
    <>
      {content}
      <ImportAnnotationsDialog />
    </>
  );
};

export default AnnotationResults;
