import { Box, Button, Checkbox, Text } from "@blasterjs/core";
import React, { useState } from "react";
import { useAppDispatch } from "../hooks";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  faCaretDown,
  faCircle as solidCircle,
  faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useAppSelector } from "../hooks";
import { mapStateToAiFragments } from "../utils";
import "./AnnotationTree.css";
import { toggleAcceptAiAnnotations } from "../slices/caseImageViewer";
import { LoggedInUser, RolePermissions } from "../permissions";
import { CaseStatus } from "../models";

library.add(faCircle);
library.add(solidCircle);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faWandMagicSparkles);

const ORPHAN = "Orphan";
const CSS_PREFIX = "annotationtree__";

const TreeBox = styled.div`
  display: flex;
  padding: 1px;
  flex-direction: column;
  width: 300px;
`;

const TreeRow = styled.div`
  display: block;
  padding: 1px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
`;

const PointTreeRow = styled.div`
  display: block;
  padding: 1px;
  padding-left: 60px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
`;

const AnnoNextHeader = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TreeCellPart = styled.div`
  float: left;
  classname: "${CSS_PREFIX}treeController";
`;

const HpfTreeRow = styled.div`
  display: block;
  padding: 2px;
  padding-left: 30px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
`;

export function copyText(t: string): string {
  const p = navigator.clipboard.writeText(t);
  const pr1 = p.catch(err => err).then(() => "done");
  return t + pr1.toString();
}

function toggleOpenTree(hele: HTMLElement, branchId: string) {
  const branchEle = document.getElementById(branchId);
  if (hele.classList.contains(`${CSS_PREFIX}treeIsOpen`)) {
    hele.classList.remove(`${CSS_PREFIX}treeIsOpen`);
    hele.classList.add(`${CSS_PREFIX}treeIsClosed`);
    branchEle && branchEle.classList.remove(`${CSS_PREFIX}treeBranchOpen`);
    branchEle && branchEle.classList.add(`${CSS_PREFIX}treeBranchClosed`);
  } else {
    hele.classList.remove(`${CSS_PREFIX}treeIsClosed`);
    hele.classList.add(`${CSS_PREFIX}treeIsOpen`);
    branchEle && branchEle.classList.remove(`${CSS_PREFIX}treeBranchClosed`);
    branchEle && branchEle.classList.add(`${CSS_PREFIX}treeBranchOpen`);
  }
}

interface Props {
  readonly caseStatus: CaseStatus | null;
  readonly loggedInUser: LoggedInUser;
}

export const AnnotationTree = ({ caseStatus, loggedInUser }: Props) => {
  const dispatch = useAppDispatch();

  const BRANCH_PREFIX = "ai_branch_";

  const aiFragments = useAppSelector(state => mapStateToAiFragments(state));
  const imageWithAnnotations = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);

  const [isAIAnnotationsVisible, setAIAnnotationsVisible] = useState(true);

  const appSequenceId =
    imageWithAnnotations !== null && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.mldFile?.appSequenceId
      : "";

  const acceptAiAnnotations =
    imageWithAnnotations !== null && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.acceptAiAnnotations
      : false;

  const imageId =
    imageWithAnnotations !== null && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.imageAndQuery.image.id
      : null;

  const canAcceptAiAnnotations =
    caseStatus &&
    caseStatus == CaseStatus.Processed &&
    loggedInUser.can([RolePermissions.AP_CaseViewer_MarkCaseasScored]);

  return (
    <>
      <AnnoNextHeader>
        <Text style={{ fontWeight: "bold" }}>Imported</Text>
        <br />
      </AnnoNextHeader>
      <Box>
        <Checkbox
          checked={acceptAiAnnotations}
          onChange={() => imageId && dispatch(toggleAcceptAiAnnotations(imageId))}
          disabled={!canAcceptAiAnnotations}
        ></Checkbox>
        <Text>Include Al Annotation Counts in Reports</Text>
        <br />
      </Box>
      <TreeBox>
        <>
          <TreeCellPart>
            <Box
              className={`${CSS_PREFIX}treeController`}
              onClick={() => {
                setAIAnnotationsVisible(!isAIAnnotationsVisible);
              }}
            >
              {!isAIAnnotationsVisible && (
                <Box className={`${CSS_PREFIX}closedTree`}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </Box>
              )}

              {isAIAnnotationsVisible && (
                <Box className={`${CSS_PREFIX}openTree`}>
                  <FontAwesomeIcon icon={faCaretDown} />
                </Box>
              )}
            </Box>
            <Box className={`${CSS_PREFIX}treeCell`}>
              <FontAwesomeIcon icon={faWandMagicSparkles} color="gray" />{" "}
              <Text style={{ fontWeight: "bold" }}>AI References</Text>
            </Box>
          </TreeCellPart>
          {isAIAnnotationsVisible && (
            <Box>
              <Text style={{ paddingLeft: "14px", text: "3D3E40" }}>
                APP Sequence: {appSequenceId}
              </Text>
            </Box>
          )}

          {isAIAnnotationsVisible &&
            aiFragments.flatMap((fragment, aidx) => {
              const hasOrphan: boolean =
                (fragment?.hpfs && fragment.hpfs.length > 0 && fragment.hpfs[0]?.name === ORPHAN) ||
                false;
              return (
                <div key={aidx} style={{ paddingLeft: "8px" }}>
                  <TreeRow
                    key={aidx}
                    className={`${CSS_PREFIX}highlight_anno_1 ${CSS_PREFIX}treeRow`}
                  >
                    {fragment.isOpen ? (
                      <>
                        <TreeCellPart>
                          <Box
                            className={`${CSS_PREFIX}treeController ${CSS_PREFIX}treeIsOpen`}
                            data-frag-name="abc"
                            onClick={(e: MouseEvent) =>
                              e &&
                              e.currentTarget &&
                              toggleOpenTree(
                                e.currentTarget as HTMLElement,
                                BRANCH_PREFIX + aidx.toString()
                              )
                            }
                          >
                            <Box className={`${CSS_PREFIX}closedTree`}>
                              <FontAwesomeIcon icon={faCaretRight} />
                            </Box>
                            <Box className={`${CSS_PREFIX}openTree`}>
                              <FontAwesomeIcon icon={faCaretDown} />
                            </Box>
                          </Box>
                        </TreeCellPart>
                        <Box className={`${CSS_PREFIX}treeCell`} color={fragment.color}>
                          <FontAwesomeIcon icon={faCircle} />
                          &nbsp;
                          <Text style={{ color: "black" }}>{fragment.name}</Text>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={(e: MouseEvent) =>
                            e && e.target && (e.target as HTMLSpanElement).innerText
                          }
                        >
                          open f
                        </Button>
                        <Box>{fragment.name}</Box>
                      </>
                    )}
                  </TreeRow>
                  <Box
                    id={BRANCH_PREFIX + aidx.toString()}
                    className={`${CSS_PREFIX}treeBranchOpen`}
                  >
                    {fragment.hpfs?.map((hpf, hidx) => (
                      <div key={aidx.toString() + "_" + hidx.toString()}>
                        <HpfTreeRow
                          key={aidx.toString() + "_" + hidx.toString()}
                          className={`${CSS_PREFIX}highlight_anno_1 ${CSS_PREFIX}treeRow`}
                        >
                          <Box
                            style={{
                              border: "thin empty black",
                              height: "30px",
                              backgroundColor: "transparent"
                            }}
                            data-hpf-id={hpf.id}
                          >
                            <TreeCellPart>
                              <Box
                                className={`${CSS_PREFIX}treeController ${CSS_PREFIX}treeIsOpen`}
                                data-frag-name="abc"
                                onClick={(e: MouseEvent) =>
                                  e &&
                                  e.currentTarget &&
                                  toggleOpenTree(
                                    e.currentTarget as HTMLElement,
                                    BRANCH_PREFIX + aidx.toString() + "_" + hidx.toString()
                                  )
                                }
                              >
                                <Box className={`${CSS_PREFIX}closedTree`}>
                                  <FontAwesomeIcon icon={faCaretRight} />
                                </Box>
                                <Box className={`${CSS_PREFIX}openTree`}>
                                  <FontAwesomeIcon icon={faCaretDown} />
                                </Box>
                              </Box>
                            </TreeCellPart>
                            <Box className={`${CSS_PREFIX}treeCell`} color={hpf.color}>
                              <FontAwesomeIcon icon={solidCircle} />
                              &nbsp;
                              <Text style={{ color: "black" }}>
                                {hpf.name || "HPF " + (hidx + (hasOrphan ? 0 : 1)).toString()}
                              </Text>
                            </Box>
                          </Box>
                        </HpfTreeRow>
                        <Box
                          id={BRANCH_PREFIX + aidx.toString() + "_" + hidx.toString()}
                          className={`${CSS_PREFIX}treeBranchOpen`}
                        >
                          {hpf.points?.map((pt, pidx) => (
                            <PointTreeRow
                              key={aidx.toString() + "_" + hidx.toString() + "_" + pidx.toString()}
                              className={`${CSS_PREFIX}highlight_anno_1 ${CSS_PREFIX}treeRow`}
                            >
                              <Box
                                className={`${CSS_PREFIX}treeCell`}
                                data-hpf-pt-cls-id={hpf.id + pt.id}
                                color={pt.color}
                              >
                                <FontAwesomeIcon height="9" width="9" icon={solidCircle} />
                                &nbsp;
                                <Text style={{ color: "black" }}>{pt.name}</Text>
                              </Box>
                              <Box className={`${CSS_PREFIX}counter`}>
                                <Text
                                  onClick={(e: MouseEvent) =>
                                    e &&
                                    e.target &&
                                    copyText((e.target as HTMLSpanElement).innerText)
                                  }
                                >
                                  {pt.count}
                                </Text>
                              </Box>
                            </PointTreeRow>
                          ))}
                        </Box>
                      </div>
                    ))}
                  </Box>
                </div>
              );
            })}
        </>
      </TreeBox>
    </>
  );
};

export default AnnotationTree;
