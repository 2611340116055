import React, { useState, useEffect } from "react";

import Content from "./Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoSidebar from "./InfoSidebar";

import { ImageWithAnnotations, QueryDetails, QueryObjectType } from "../models";

import { hasOpenQueries, hasAnsweredQueries } from "../slices/queryDetails";

import {
  faArrowLeft,
  faArrowRight,
  faClipboard,
  faEye,
  faFlag
} from "@fortawesome/free-solid-svg-icons";
import SidebarPanelImage from "./SidebarPanelImage";
import styled from "styled-components";
import SidebarPanelAnnotations from "./SidebarPanelAnnotations";
import SidebarPanelQueries from "./SidebarPanelQueries";
import { LoggedInUser } from "../permissions";
import { AppDispatch } from "../store";

interface Props {
  readonly queryDetails: QueryDetails;
  readonly imageWithAnnotations: ImageWithAnnotations;
  readonly loggedInUser: LoggedInUser;
  dispatch: AppDispatch;
}

const PanelIcon = styled.div`
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
  transition: opacity 0.2s ease-in-out;
`;

const NotificationDot = styled.div`
  position: absolute;
  left: -13px;
  top: -3px;
  width: 11px;
  height: 11px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
`;

const InfoSidebarImage = ({
  queryDetails,
  imageWithAnnotations,
  loggedInUser,
  dispatch
}: Props) => {
  const imageAndQuery = imageWithAnnotations.imageAndQuery;

  const [hideSidebar, setHideSidebar] = useState(false);

  const toggleHideSidebar = function () {
    hideSidebar ? setHideSidebar(false) : setHideSidebar(true);
  };

  const [selectedPanel, setSelectedPanel] = useState(0);
  const [hoveredPanel, setHoveredPanel] = useState(-1);

  const selectPanel = function (index: number) {
    setSelectedPanel(index);
    setHideSidebar(false);
  };

  const isNotificationQueryIcon = function () {
    return (
      hasOpenQueries(queryDetails, null, imageAndQuery) ||
      hasAnsweredQueries(queryDetails, null, imageAndQuery)
    );
  };

  const [notificationQueryIcon, setNotificationQueryIcon] = useState(isNotificationQueryIcon());

  useEffect(() => {
    setNotificationQueryIcon(isNotificationQueryIcon());
  }, [queryDetails]);

  const panels = [
    {
      // Added transform as spacing between eye icon seems strange as it's height looks smaller than the rest
      icon: (
        <FontAwesomeIcon
          icon={faEye}
          title="Overview"
          style={{ width: "100%", height: "100%", marginBottom: "-2px", transform: "scale(1.1)" }}
        />
      ),
      content: (
        <SidebarPanelImage
          imageWithAnnotations={imageWithAnnotations}
          loggedInUser={loggedInUser}
          dispatch={dispatch}
        ></SidebarPanelImage>
      )
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faClipboard}
          title="Annotations"
          style={{ width: "100%", height: "100%" }}
        />
      ),
      content: (
        <SidebarPanelAnnotations
          selectedImage={imageAndQuery}
          caseStatus={null}
          userCanDeleteAnnotations={true}
          loggedInUser={loggedInUser}
        ></SidebarPanelAnnotations>
      )
    },
    {
      icon: (
        <div style={{ position: "relative", display: "inline-flex", alignItems: "center" }}>
          {notificationQueryIcon ? <NotificationDot></NotificationDot> : null}
          <FontAwesomeIcon
            icon={faFlag}
            title="Queries"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
      content: (
        <SidebarPanelQueries
          histoCase={null}
          selectedImage={imageAndQuery}
          queryDetails={queryDetails}
          currentViewQueryObjectType={QueryObjectType.Image}
          loggedInUser={loggedInUser}
        ></SidebarPanelQueries>
      )
    }
  ];

  const iconDock = (
    <div
      style={{
        height: "100%",
        width: "50px",
        borderRight: "solid 2px #ccc",
        padding: "5px",
        float: "left"
      }}
    >
      {panels.map((panel, index) => (
        <PanelIcon
          key={index}
          style={{
            color: selectedPanel === index ? "#57B7B3" : "#9A9DA0",
            opacity: hoveredPanel === index && selectedPanel !== index ? 0.8 : 1
          }}
          onClick={() => {
            selectPanel(index);
          }}
          onMouseEnter={() => setHoveredPanel(index)}
          onMouseLeave={() => setHoveredPanel(-1)}
        >
          {panel.icon}
        </PanelIcon>
      ))}
      <PanelIcon style={{ color: "teal" }} onClick={toggleHideSidebar}>
        <FontAwesomeIcon
          icon={hideSidebar ? faArrowLeft : faArrowRight}
          style={{ position: "absolute", bottom: "15px" }}
        />
      </PanelIcon>
    </div>
  );

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {iconDock}
      <InfoSidebar style={{ display: hideSidebar ? "none" : "block" }}>
        <Content isLoading={false}>{panels[selectedPanel]?.content}</Content>
      </InfoSidebar>
    </div>
  );
};

export default InfoSidebarImage;
