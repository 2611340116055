import React, { useState, Dispatch, SetStateAction } from "react";
import { Button, Text, Box, TextArea, Radio, Dialog, Label } from "@blasterjs/core";
import { DialogHeader, DialogBody } from "./DialogLayout";
import { QueryResponseOption } from "../models";
import { useAppDispatch } from "../hooks";
import { markAsResolved } from "../slices/queryDetails";

interface RespondQueryDialogProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  queryId: string;
}

const RespondQueryDialog: React.FC<RespondQueryDialogProps> = ({
  openDialog,
  setOpenDialog,
  queryId
}) => {
  const dispatch = useAppDispatch();

  const [comment, setComment] = useState("");
  const [responseOptionId, setResponseOptionId] = useState("");

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newComment = e.target.value;
    setComment(newComment);
  };

  const onSubmitClick = () => {
    // send api request to mark query answered
    dispatch(
      markAsResolved({
        queryId: queryId,
        resolutionOption: responseOptionId,
        commentText: comment
      })
    );
    setComment("");
    setResponseOptionId("");
    setOpenDialog(!openDialog);
  };

  return (
    <Dialog isOpen={openDialog} onRequestClose={() => setOpenDialog(false)}>
      <DialogHeader title="Mark as Resolved" closeDialog={() => setOpenDialog(false)} />

      <DialogBody>
        <Label>
          <Text className="query-info-listing-text">Resolution Option:</Text>
          <Box>
            {Object.entries(QueryResponseOption).map(([optionKey, optionValue]) => {
              return (
                <Label
                  key={"l_" + optionKey}
                  style={{ display: "flex", cursor: "pointer", marginTop: "1rem", gap: "10px" }}
                >
                  <Radio
                    key={"r_" + optionKey}
                    checked={optionKey === responseOptionId}
                    onClick={() => setResponseOptionId(optionKey)}
                  />
                  <Text key={"t_" + optionKey}>{optionValue}</Text>
                </Label>
              );
            })}
          </Box>
        </Label>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
            marginBottom: "1rem"
          }}
        >
          <Label className="query-info-listing-text">
            Details&nbsp;
            <Label style={{ fontWeight: "400", fontSize: "18px", fontStyle: "italic" }}>
              optional
            </Label>
          </Label>
          <TextArea
            placeholder=""
            className="query-comment-textarea"
            onChange={onCommentChange}
            defaultValue={comment}
          />
        </Box>
        <Box
          style={{
            backgroundColor: "rgba(227, 43, 36, 0.2)",
            borderRadius: "4px",
            padding: "8px",
            gap: "10px"
          }}
        >
          <Text style={{ color: "#111111" }}>
            <Text style={{ fontWeight: "bold" }}>Disclaimer:</Text> This decision is FINAL and WILL
            progress the query to Answered.
          </Text>
        </Box>
      </DialogBody>

      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button
          className="query-blue-button"
          ml="auto"
          width="25%"
          disabled={responseOptionId === ""}
          onClick={onSubmitClick}
        >
          {" "}
          Submit{" "}
        </Button>
        <Button
          className="query-white-button"
          ml="1"
          width="25%"
          onClick={() => setOpenDialog(false)}
        >
          {" "}
          Cancel{" "}
        </Button>
      </Box>
    </Dialog>
  );
};

export default RespondQueryDialog;
