import React, { useState, useRef, useEffect, useMemo } from "react";
import { CaseWithImages, ImageAndQuery } from "../models";
import { LoggedInUser } from "../permissions";
import styled from "styled-components";
import { Box, Table } from "@blasterjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

interface Props {
  readonly loggedInUser: LoggedInUser;
  readonly histoCase: CaseWithImages | null;
  readonly selectedImage: ImageAndQuery | null;
}

const DetailsPanel = styled.div`
  background-color: #f0f2f5;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  gap: 4px;
`;

const DetailPanelHeader = styled.p`
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0%;
  display: inline;
  word-break: normal;
`;

const DetailPanelData = styled.p`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0%;
  display: inline;
  margin-left: 0.5rem;
  word-break: break-all;
`;

const SidebarPanelCaseDetails = ({ loggedInUser, histoCase, selectedImage }: Props) => {
  const detailsBoxRef = useRef<HTMLDivElement>(null);

  const [overflowingText, setOverflowingText] = useState<Array<string>>([]);
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const caseDetails = useMemo(
    () => [
      {
        header: "Histo",
        data: histoCase ? histoCase.caseWithStatus.histoProcedureId : null,
        crCanView: true
      },
      {
        header: "Endo",
        data: histoCase ? histoCase.caseWithStatus.procId : null,
        crCanView: true
      },
      {
        header: "Accession",
        data: selectedImage ? selectedImage.image.accessionNumber : null,
        crCanView: true
      },
      {
        header: "Subject ID",
        data:
          histoCase && "subjectId" in histoCase.caseWithStatus
            ? histoCase.caseWithStatus.subjectId
            : null,
        crCanView: false
      },
      {
        header: "Site ID",
        data:
          histoCase && "siteId" in histoCase.caseWithStatus
            ? histoCase.caseWithStatus.siteId
            : null,
        crCanView: false
      },
      {
        header: "Visit ID",
        data:
          histoCase && "visitId" in histoCase.caseWithStatus
            ? histoCase.caseWithStatus.visitId
            : null,
        crCanView: false
      }
    ],
    [histoCase, selectedImage]
  );

  const checkDetailsTextOverflow = () => {
    if (detailsBoxRef.current) {
      const children = detailsBoxRef.current.children;

      if (children) {
        const wrappedLines = Array.from(children).map((child, index) => {
          const childHTML = child as HTMLParagraphElement;
          if (index > 1) {
            const caseDetail = caseDetails.find(detail => detail.data === childHTML.textContent);
            return caseDetail ? caseDetail.header : "";
          }

          return "";
        });

        setOverflowingText(wrappedLines);
      }
    }
  };

  useEffect(() => {
    checkDetailsTextOverflow();
  }, [caseDetails]);

  return (
    <DetailsPanel>
      <Table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <Box>
                <div ref={detailsBoxRef}>
                  {caseDetails.map((detail, index) => {
                    if (
                      detail.data &&
                      (detailsExpanded ||
                        (!detailsExpanded && !overflowingText.includes(detail.header)))
                    ) {
                      if ((loggedInUser.isCR() && detail.crCanView) || !loggedInUser.isCR()) {
                        return (
                          <>
                            <DetailPanelHeader key={"detail-header-" + index.toString()}>
                              {detail.header.replace(/ /g, "\u00A0")}:
                            </DetailPanelHeader>
                            <DetailPanelData key={"detail-data-" + index.toString()}>
                              {detail.data}
                            </DetailPanelData>
                            &nbsp;
                            <br />
                          </>
                        );
                      }
                    }
                  })}
                </div>
              </Box>
            </td>
            <td style={{ paddingRight: "0px", marginRight: "0px", textAlign: "right" }}>
              {detailsExpanded ? (
                <Box onClick={() => setDetailsExpanded(false)} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faCircleMinus}
                    style={{ textAlign: "right", color: "#3D3E40", marginLeft: "1rem" }}
                  />
                </Box>
              ) : (
                <Box onClick={() => setDetailsExpanded(true)} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{ textAlign: "right", color: "#3D3E40", marginLeft: "1rem" }}
                  />
                </Box>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </DetailsPanel>
  );
};

export default SidebarPanelCaseDetails;
