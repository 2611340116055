/* eslint-disable functional/immutable-data */
import React from "react";
import { Button, Box } from "@blasterjs/core";
import { TableColumnVisibilityObject, DefaultRoleColumnVisibility } from "../models";

import Page, { PageHeader, PageHeading, PageTabs } from "../components/Page";

import { useParams } from "react-router";
import styled from "styled-components";
import { LoggedInUser, RolePermissions } from "../permissions";
import { getCookieTableColumnVisibility } from "../components/ImageManagementShowHideColumns";
import { Resource } from "../types";
import CaseResults from "../components/ImageManagementCaseResults";
import ImageResults from "../components/ImageManagementImageResults";
import AnnotationResults from "../components/ImageManagementAnnotationResults";
import { useAppSelector } from "../hooks";

export const SubNavLink = styled(Button)`
  border: none;
  background: ${props => (props.active ? "#fff" : "transparent")};
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: none;
  margin-right: 4px;
  border: 1px solid #dde0e6;
  border-width: ${props => (props.active && props.type === "tabs" ? "0 1px" : 0)};
  &:hover {
    background-color: ${props => (props.logo ? "transparent" : "rgba(179, 189, 204, 0.15)")};
  }
  &:after {
    position: absolute;
    top: ${props => (props.active && props.type === "tabs" ? 0 : "auto")};
    bottom: ${props => (props.active && props.type === "tabs" ? "auto" : 0)};
    left: 0;
    border-radius: 20px;
    right: 0;
    height: 2px;
    content: "";
    background-color: ${props => (props.active ? props.theme.colors.brand.color : "transparent")};
  }
`;

const ImageManagement = () => {
  const params = useParams();
  const tab: string = params.tab || "images";

  // const navigate = useNavigate();
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const content = (() => {
    switch (tab) {
      case "images":
        return <ImageResults />;
      case "cases":
        return <CaseResults />;
      case "annotations":
        return <AnnotationResults />;
      default:
        return <></>;
    }
  })();

  // permissions check
  // if ("resource" in loggedInUser) {
  //   if (tab == "images") {
  //     navigate(`/accessDenied`);
  //   } else {
  //     navigate(`/accessDenied`);
  //   }
  // }

  const userCanSeeCases =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ViewPage]);
  const userCanSeeImages =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_ImageListTab_ViewImageslistTab]);
  const userCanSeeMldFiles =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.IM_ImageListTab_ViewImageslistTab]); //todo: new permission

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Image Management</PageHeading>
        </PageHeader>
        <PageTabs
          links={[
            ...(userCanSeeCases
              ? [
                  {
                    to: `/image-management/images`,
                    label: "Images"
                  }
                ]
              : []),
            ...(userCanSeeImages
              ? [
                  {
                    to: `/image-management/cases`,
                    label: "Cases"
                  }
                ]
              : []),
            ...(userCanSeeMldFiles
              ? [
                  {
                    to: `/image-management/annotations`,
                    label: "Annotations"
                  }
                ]
              : [])
          ]}
        />
        {content}
      </Box>
    </Page>
  );
};

export const WideTableContainer = styled.div`
  flex: 1;
  min-width: 80vw;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${props => props.theme.colors.gray200};
`;

export const TableButton = styled(Button)`
  border: 1px solid #d9d9d9;
  background: #f5f8fa;
  border-radius: 4px;
  border-width: 1px;
  text-align: center;
  margin-right: 2rem;
`;

export const generateTableColumnVisibility = (
  defaultColumns: TableColumnVisibilityObject,
  defaultRoleVisibility: DefaultRoleColumnVisibility,
  loggedInUser: Resource<LoggedInUser>,
  cookieName: string
) => {
  const cookieData = getCookieTableColumnVisibility(cookieName);

  if (!cookieData) {
    return Object.entries(defaultColumns).reduce((acc, [key, value]) => {
      const loggedInUserRoleName =
        "resource" in loggedInUser && loggedInUser.resource.user.workAsRole
          ? loggedInUser.resource.user.workAsRole.name
          : null;

      const visibility =
        loggedInUserRoleName && defaultRoleVisibility[key]
          ? defaultRoleVisibility[key]?.[loggedInUserRoleName] ?? true // Default to true if undefined
          : true;
      acc[key] = {
        name: value.name,
        visible: visibility,
        permissionToView: value.permissionToView
      };
      return acc;
    }, {} as TableColumnVisibilityObject);
  } else {
    return cookieData;
  }
};

export const BulkOperationTableContainer = styled.div`
  display: flex;
  max-width: 144rem;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.gray200};
`;
export const MenuOption = styled.div`
  margin-right: 15px;
`;

// Bulk Operations are disabled for now

// type BulkOperationToolbarProps = {
//   tab: string;
//   loggedInUser: LoggedInUser;
//   selectedCount: number;
// };

// const BulkOperationToolbar = (props: BulkOperationToolbarProps) => {
//   const dispatch = useAppDispatch();

//   if (props.tab == "case")
//     return (
//       <BulkOperationTableContainer>
//         <MenuOption>Selected {props.selectedCount}</MenuOption>
//         <MenuOption
//           onClick={() =>
//             dispatch(
//               openImageManagementCaseDialogParent({
//                 caseRowOption: CaseRowOptions.RevertStatus
//               })
//             )
//           }
//         >
//           Revert Status
//         </MenuOption>
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_PutoHold]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.PutOnHold
//                   })
//                 )
//               }
//             >
//               Put On Hold
//             </MenuOption>
//           )}
//         {props.selectedCount == 1 &&
//           "resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_DownloadImages]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.Download
//                   })
//                 )
//               }
//             >
//               Download Images
//             </MenuOption>
//           )}

//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_CaseListTab_ArchiveCase]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementCaseDialogParent({
//                     caseRowOption: CaseRowOptions.Archive
//                   })
//                 )
//               }
//             >
//               Archive
//             </MenuOption>
//           )}
//       </BulkOperationTableContainer>
//     );
//   else
//     return (
//       <BulkOperationTableContainer>
//         <MenuOption>Selected {props.selectedCount}</MenuOption>
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_AssignImagetoCase]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.AssignToCase,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Assign to Case
//             </MenuOption>
//           )}

//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_CopyImage]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.CopyImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Copy
//             </MenuOption>
//           )}
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.S_ImageListTab_MoveImage]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.MoveImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Move
//             </MenuOption>
//           )}

//         {props.selectedCount == 1 &&
//           "resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_DownloadImages]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.DownloadImage,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Download Image
//             </MenuOption>
//           )}
//         {"resource" in props.loggedInUser &&
//           props.loggedInUser.can([RolePermissions.IM_ImagesListTab_Archive]) && (
//             <MenuOption
//               onClick={() =>
//                 dispatch(
//                   openImageManagementImageDialogParent({
//                     imageRowOptions: ImageRowOptions.Archive,
//                     imageId: null
//                   })
//                 )
//               }
//             >
//               Archive
//             </MenuOption>
//           )}
//       </BulkOperationTableContainer>
//     );
// };

export default ImageManagement;
