import React from "react";
import { Circle, Popup } from "react-leaflet";
import { Text as BlasterText } from "@blasterjs/core";
import { UUID } from "../models";
import { LatLngLiteral } from "leaflet";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";
import { useAppSelector } from "../hooks";

interface Props {
  readonly text: string | null;
  readonly date: string;
  readonly radius: number;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly isClickable: boolean;
  readonly weight: number;
  readonly canDelete: boolean;
  readonly dashArray: string | null;
  readonly hpfClassId?: string;
  readonly opacity: number | null;
  dispatch: AppDispatch;
}

export const HPF_CURSOR = "HPF cursor";

const HPFAnnotation = ({
  position,
  radius,
  text,
  date,
  annotationId,
  color,
  weight,
  canDelete,
  dashArray,
  hpfClassId,
  opacity,
  dispatch
}: Props) => {
  const hpfOpacity = opacity == null ? 1 : opacity;

  //this is type any because the leaflet type didn't get picked up by the recent react-leaflet
  //TODO check this next upgrade, the type should be CircleProps
  const cp: any = {
    center: position,
    radius: radius
  };
  const biggerRadius: any = {
    center: position,
    radius: radius * 1.1
  };
  const hpfVisibility = useAppSelector(state => state.caseImageViewer.hpfVisibility);

  const hidden = hpfVisibility.find(
    x =>
      x.hpfClassid === hpfClassId &&
      (x.hpfId === null || (x.hpfId === annotationId && x.pointClassId === null))
  );

  return hidden ? (
    <></>
  ) : (
    <>
      {/* This is the fully functional HPF with border and popup */}
      {/* console.log("circle weight: " + weight.toString() +
            " radius: " + radius.toString() +
            " lat " + position.lat.toString())*/}
      {text === HPF_CURSOR ? (
        <Circle
          center={position}
          {...cp}
          pathOptions={{
            color: color || "#00FF00",
            dashArray: dashArray || "",
            weight: weight,
            opacity: hpfOpacity,
            fillOpacity: 0
          }}
        >
          <>{/*console.log("hpf ___ color in: " + color + " text " + text)*/}</>
        </Circle>
      ) : weight > 5 ? (
        <Circle
          center={position}
          {...biggerRadius}
          pathOptions={{
            color: color || "#00FF00",
            weight: weight,
            opacity: hpfOpacity,
            fillOpacity: 0
          }}
        ></Circle>
      ) : (
        <>
          <Circle
            {...cp}
            pathOptions={{
              color: color || "#00FF00",
              dashArray: dashArray || "",
              weight: weight,
              opacity: hpfOpacity,
              fillOpacity: 0
            }}
          >
            <Popup closeButton={false} className="annotation-popup">
              <AnnotationTooltip
                annotationId={annotationId}
                canDelete={canDelete}
                dispatch={dispatch}
              >
                <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
                  <BlasterText fontSize="10pt" fontWeight="bold">
                    {text}
                  </BlasterText>
                  <br />
                  <BlasterText fontSize="8pt">{date}</BlasterText>
                </div>
              </AnnotationTooltip>
            </Popup>
          </Circle>
          {/* This one is the same size with no border. Clicking it is a no-op so it eats up the clicks
            of the inside of the other circle since it's on top
          <Circle weight={0} {...commonProps} />
            */}
          <Circle
            pathOptions={{
              weight: 0,
              fillOpacity: 0
            }}
            {...cp}
          />
        </>
      )}
    </>
  );
};

export default HPFAnnotation;
