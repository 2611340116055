import { LatLngLiteral } from "leaflet";
import React from "react";
import { Text as BlasterText } from "@blasterjs/core";
import { Circle, Popup } from "react-leaflet";
import { Indication, UUID } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";
import { useAppSelector } from "../hooks";

interface Props {
  readonly date: string;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly canDelete: boolean;
  readonly weight: number;
  readonly scaleFactor: number;
  readonly hpfId?: UUID;
  readonly pointClassId?: string;
  readonly hpfClassId?: string;
  readonly opacity: number | null;
  dispatch: AppDispatch;
}

export const defaultProps = {
  radius: 3,
  color: "black",
  fillColor: "#FFFF00",
  fillOpacity: 1,
  weight: 5,
  zIndex: 999,
  stroke: false
};

const PointAnnotation = ({
  position,
  date,
  annotationId,
  color,
  weight,
  canDelete,
  scaleFactor,
  hpfId,
  pointClassId,
  hpfClassId,
  opacity,
  dispatch
}: Props) => {
  const indications = useAppSelector(
    state => state.studyConfiguration.study.data.indications.value
  );

  const defaultRadius = indications.includes(Indication.CeliacDisease) ? 1.5 : 3;
  const defaultOpacity = indications.includes(Indication.CeliacDisease) ? 0.75 : 1;
  const pointOpacity = opacity == null ? defaultOpacity : opacity;

  const props = {
    ...defaultProps,
    weight: 0,
    radius: defaultRadius * scaleFactor,
    fillOpacity: pointOpacity,
    strokeOpacity: pointOpacity,
    ...(color ? { fillColor: color } : {})
  };
  const hprops = {
    ...defaultProps,
    weight: 1,
    radius: defaultRadius * scaleFactor * 7, // the multiplier for highlighting
    fillOpacity: pointOpacity,
    strokeOpacity: pointOpacity,
    ...(color ? { fillColor: color } : {})
  };

  const hpfVisibility = useAppSelector(state => state.caseImageViewer.hpfVisibility);

  const hidden = hpfVisibility.find(
    x =>
      x.hpfClassid === hpfClassId &&
      (x.hpfId === null ||
        (x.hpfId === hpfId && (x.pointClassId === pointClassId || x.pointClassId === null)))
  );

  return hidden ? (
    <></>
  ) : (
    <>
      {weight > 5 ? <Circle center={position} {...hprops}></Circle> : <></>}
      <Circle center={position} {...props}>
        <Popup closeButton={false} className="annotation-popup">
          <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
            <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
              <BlasterText fontSize="8pt">{date}</BlasterText>
            </div>
          </AnnotationTooltip>
        </Popup>
      </Circle>
    </>
  );
};

export default PointAnnotation;
