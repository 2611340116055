import React from "react";

import { Box, Heading } from "@blasterjs/core";

import { CaseStatus, ImageAndQuery } from "../models";
import { useAppDispatch } from "../hooks";
import { LoggedInUser, RolePermissions } from "../permissions";
import { toggleSidebarExpanded } from "../slices/caseImageViewer";
import { CollapseIcon } from "./Expandable";
import AnnotationNestPanel from "./AnnotationNest";
import AnnotationTree from "./AnnotationTree";

interface Props {
  readonly selectedImage: ImageAndQuery | null;
  readonly caseStatus: CaseStatus | null;
  readonly userCanDeleteAnnotations: boolean;
  readonly loggedInUser: LoggedInUser;
}

const SidebarPanelAnnotations = ({
  selectedImage,
  caseStatus,
  userCanDeleteAnnotations,
  loggedInUser
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Box display="flex" width="100%">
        <Box textAlign="left">
          <Heading as="h5">Annotations</Heading>
        </Box>
        <Box ml={"auto"}>
          <CollapseIcon onToggle={() => dispatch(toggleSidebarExpanded())} />
        </Box>
      </Box>
      <hr />

      {selectedImage !== null &&
        loggedInUser.can([RolePermissions.AP_ImageCaseViewer_ViewAnnotationstab]) && (
          <>
            <AnnotationNestPanel
              userCanDeleteAnnotations={userCanDeleteAnnotations}
              imageId={selectedImage.image.id}
            />
            <hr />
            <AnnotationTree caseStatus={caseStatus} loggedInUser={loggedInUser} />
          </>
        )}
    </>
  );
};

export default SidebarPanelAnnotations;
