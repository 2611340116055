import React, { Dispatch, SetStateAction, useState } from "react";
import { Dialog, Table, Checkbox, Button } from "@blasterjs/core";
import { DialogHeader, DialogBody, DialogFooter } from "./DialogLayout";
import {
  TableColumnVisibilityObject,
  TableColumnVisibility,
  ImageManagementTableColumn,
  RoleName,
  DefaultRoleColumnVisibility
} from "../models";
import Cookies from "js-cookie";
import { LoggedInUser, RolePermissions } from "../permissions";

export const IMAGES_TABLE_VISIBILITY_COOKIE_NAME = "IM_IMAGES_TABLE_VISIBILITY";
export const CASES_TABLE_VISIBILITY_COOKIE_NAME = "IM_CASES_TABLE_VISIBILITY";

export const imageTableDefaultRoleColumnVisibility: DefaultRoleColumnVisibility = {
  [ImageManagementTableColumn.AccessionNumber]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.Sponsor]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.StudyName]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.ProtocolID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.SiteID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.SubjectID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.VisitID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.LabVisitID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.HistoProcedureID]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: false,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.EndoProcedureID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.AnatomicalSegment]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.CollectionDate]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.ImageUploadDate]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.ImageStatus]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.InQuery]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.Filename]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.QAComments]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.Organization]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  }
};

export const imageTableDefaultColumnVisibility: TableColumnVisibilityObject = {
  [ImageManagementTableColumn.AccessionNumber]: {
    name: "Accession Number",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewAccesionNumber
  },
  [ImageManagementTableColumn.Sponsor]: {
    name: "Sponsor",
    visible: true,
    permissionToView: RolePermissions.AP_StudyData_ViewSponsor
  },
  [ImageManagementTableColumn.StudyName]: {
    name: "Study Name",
    visible: true,
    permissionToView: RolePermissions.AP_StudyData_ViewStudyName
  },
  [ImageManagementTableColumn.ProtocolID]: {
    name: "Protocol ID",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewProtocolID
  },
  [ImageManagementTableColumn.SiteID]: {
    name: "Site ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewSiteID
  },
  [ImageManagementTableColumn.SubjectID]: {
    name: "Subject ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewSubjectID
  },
  [ImageManagementTableColumn.VisitID]: {
    name: "Visit ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewVisitID
  },
  [ImageManagementTableColumn.LabVisitID]: {
    name: "Lab Visit ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewVisitID
  },
  [ImageManagementTableColumn.HistoProcedureID]: {
    name: "Histo Procedure ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewHistopathologyProcedureID
  },
  [ImageManagementTableColumn.EndoProcedureID]: {
    name: "Endo Procedure ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewEndoProcedureID
  },
  [ImageManagementTableColumn.AnatomicalSegment]: {
    name: "Anatomical Segement",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewAnatomicalSegment
  },
  [ImageManagementTableColumn.CollectionDate]: {
    name: "Collection Date",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewCollectionDate
  },
  [ImageManagementTableColumn.ImageUploadDate]: {
    name: "Upload Date",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewImageUploadDate
  },
  [ImageManagementTableColumn.ImageStatus]: {
    name: "Status",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewImageStatus
  },
  [ImageManagementTableColumn.InQuery]: {
    name: "In Query",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewInQuery
  },
  [ImageManagementTableColumn.Filename]: {
    name: "File Name",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewFilename
  },
  [ImageManagementTableColumn.QAComments]: {
    name: "QA Comments",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewQAComments
  },
  [ImageManagementTableColumn.Organization]: {
    name: "Organization",
    visible: true,
    permissionToView: RolePermissions.AP_ImageData_ViewUploaderOrganization
  }
};

export const caseTableDefaultRoleColumnVisibility: DefaultRoleColumnVisibility = {
  [ImageManagementTableColumn.HistoProcedureID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.EndoProcedureID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.StudyName]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.SiteID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.SubjectID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.VisitID]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.CaseDateCreated]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: false,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.ImagesExpected]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.ImagesReceived]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.CaseStatus]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.Queries]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: true,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: true
  },
  [ImageManagementTableColumn.LabTechQC]: {
    [RoleName.SystemAdmin]: true,
    [RoleName.StudyAdmin]: true,
    [RoleName.ISC]: true,
    [RoleName.LabTech]: true,
    [RoleName.Uploader]: true,
    [RoleName.PM]: false,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.ISCQC]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: true,
    [RoleName.DM]: false,
    [RoleName.Sponsor]: false
  },
  [ImageManagementTableColumn.CRAssignment]: {
    [RoleName.SystemAdmin]: false,
    [RoleName.StudyAdmin]: false,
    [RoleName.ISC]: false,
    [RoleName.LabTech]: false,
    [RoleName.Uploader]: false,
    [RoleName.PM]: false,
    [RoleName.DM]: true,
    [RoleName.Sponsor]: false
  }
};

export const caseTableDefaultColumnVisibility: TableColumnVisibilityObject = {
  [ImageManagementTableColumn.HistoProcedureID]: {
    name: "Histo Procedure ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewHistopathologyProcedureID
  },
  [ImageManagementTableColumn.EndoProcedureID]: {
    name: "Endo Procedure ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewEndoProcedureID
  },
  [ImageManagementTableColumn.StudyName]: {
    name: "Study Name",
    visible: true,
    permissionToView: RolePermissions.AP_StudyData_ViewStudyName
  },
  [ImageManagementTableColumn.SiteID]: {
    name: "Site ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewSiteID
  },
  [ImageManagementTableColumn.SubjectID]: {
    name: "Subject ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewSubjectID
  },
  [ImageManagementTableColumn.VisitID]: {
    name: "Visit ID",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewVisitID
  },
  [ImageManagementTableColumn.CaseDateCreated]: {
    name: "Date Created",
    visible: true,
    permissionToView: null
  },
  [ImageManagementTableColumn.ImagesExpected]: {
    name: "Images Expected",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewExpectedNoofimages
  },
  [ImageManagementTableColumn.ImagesReceived]: {
    name: "Images Received",
    visible: true,
    permissionToView: RolePermissions.AP_CaseData_ViewNoofimagesReceived
  },
  [ImageManagementTableColumn.CaseStatus]: {
    name: "Status",
    visible: true,
    permissionToView: null
  },
  [ImageManagementTableColumn.Queries]: { name: "Queries", visible: true, permissionToView: null },
  [ImageManagementTableColumn.LabTechQC]: {
    name: "Lab Tech QC",
    visible: true,
    permissionToView: null
  },
  [ImageManagementTableColumn.ISCQC]: { name: "ISC QC", visible: true, permissionToView: null },
  [ImageManagementTableColumn.CRAssignment]: {
    name: "CR Assignment",
    visible: true,
    permissionToView: null
  }
};

export const getCookieTableColumnVisibility = (
  cookieName: string
): TableColumnVisibilityObject | null => {
  const cookie = Cookies.get(cookieName);
  return cookie ? (JSON.parse(cookie) as TableColumnVisibilityObject) : null;
};

const storeCookieTableColumnVisibility = (
  columns: TableColumnVisibilityObject,
  cookieName: string
) => {
  Cookies.set(cookieName, columns);
};

interface Props {
  columns: TableColumnVisibilityObject;
  setColumns: Dispatch<SetStateAction<TableColumnVisibilityObject>>;
  isDialogOpen: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  cookieName: string;
  loggedInUser: LoggedInUser;
}

const ImageManagementShowHideColumns = ({
  columns,
  setColumns,
  isDialogOpen,
  setOpenDialog,
  cookieName,
  loggedInUser
}: Props) => {
  const [columnsVisible, setColumnsVisible] = useState<TableColumnVisibilityObject>({ ...columns });

  const toggleColumn = (key: string, value: TableColumnVisibility) => {
    const newColumnsVisible = {
      ...columnsVisible,
      [key]: { name: value.name, visible: !value.visible, permissionToView: value.permissionToView }
    };
    setColumnsVisible(newColumnsVisible);
  };

  const applyUpdatedColumnVisibikity = () => {
    setColumns(columnsVisible);
    storeCookieTableColumnVisibility(columnsVisible, cookieName);
    setOpenDialog(false);
  };

  const columnsToDisplay = () => {
    return Object.entries(columnsVisible).map(([key, value], index) => {
      if (
        !value.permissionToView ||
        (value.permissionToView && loggedInUser.can([value.permissionToView]))
      ) {
        return (
          <tr key={`${value.name}-${index}`}>
            <td style={{ display: "flex" }}>
              <Checkbox
                checked={value.visible}
                onChange={() => toggleColumn(key, value)}
                style={{ marginRight: "1rem" }}
              />
              <div style={{ fontWeight: "400", fontSize: "16px", color: "#111111" }}>
                {value.name}
              </div>
            </td>
          </tr>
        );
      }
    });
  };

  return (
    <Dialog isOpen={isDialogOpen} onRequestClose={() => setOpenDialog(false)}>
      <DialogHeader title="Show/Hide Columns" closeDialog={() => setOpenDialog(false)} />
      <DialogBody>
        <Table style={{ width: "100%", overflowX: "clip" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top", width: "50%", padding: "0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <tbody>
                    {columnsToDisplay()
                      .slice(0, Math.ceil(Object.entries(columnsVisible).length / 2))
                      .map(value => {
                        return value;
                      })}
                  </tbody>
                </table>
              </td>

              <td style={{ verticalAlign: "top", width: "50%", padding: "0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <tbody>
                    {columnsToDisplay()
                      .slice(Math.ceil(Object.entries(columnsVisible).length / 2))
                      .map(value => {
                        return value;
                      })}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </Table>
      </DialogBody>
      <DialogFooter>
        <Button ml="auto" width="25%" onClick={applyUpdatedColumnVisibikity}>
          {" "}
          Apply{" "}
        </Button>
        <Button ml="1" width="25%" onClick={() => setOpenDialog(false)}>
          {" "}
          Cancel{" "}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default ImageManagementShowHideColumns;
